/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from '../../components/AppWrapper';
import Table from './table';

// import { cloneCollections } from '../../helpers/data';

import { getNotesList } from '../../actions/notes';
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from '../../actions/misc';

class NotesListPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get articles
        this.props.dispatch(getNotesList());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { notesList, randNum } = this.props;
        if ( notesList && randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('notes_list'));
    }

    handleRefresh = (props) => {
        // get notes
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('notes_list'));
        this.props.dispatch(getNotesList(props));
    }

    renderContents() {
        const { authData, notesList } = this.props;
        return <Table
                list={notesList}
                authData={authData}
                onRefresh={this.handleRefresh} />;
    }

    render() {
        const { randNum } = this.state;
        const { notesList, authData } = this.props;
        return <AppWrapper 
                title="Notes"
                onLoad={( !( randNum ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        notesList: state.notes && state.notes.list ? state.notes.list : null,
        randNum: state.notes && state.notes.rand ? state.notes.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(NotesListPage);