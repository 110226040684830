/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_ARTICLES,
    GET_ARTICLES_OPTIONS,
    GET_ARTICLE,
	ADD_ARTICLE,
	EDIT_ARTICLE,
	DELETE_ARTICLE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getArticles, fb_getArticlesOptions, fb_getArticle, fb_addArticle, fb_updateArticle, fb_deleteArticle } from './fb';


export const getArticles = (props) => {
	return dispatch => {

		fb_getArticles(props)
		.then(list => {
			dispatch({
				type: GET_ARTICLES,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_ARTICLES,
				payload: { list: [] }
			});
		})

	}
}

// export const getArticlesOptions = (props) => {
// 	return dispatch => {

// 		fb_getArticlesOptions(list => {
// 			dispatch({ 
//                 type: GET_ARTICLES_OPTIONS, 
//                 payload: { list } 
//             });
// 		},props);

// 	}
// }

export const getArticle = (id) => {
	return dispatch => {

		fb_getArticle(id)
		.then(article => {
			dispatch({
				type: GET_ARTICLE,
				payload: { article }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_ARTICLE,
				payload: { article: {} }
			});
		})

	}
}

export const addArticle = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addArticle(formData)
		.then( results => {
			if ( results && results.new_article_id && !_isEmpty( results.new_article_id ) ) {
				// updated
				dispatch({ type: ADD_ARTICLE });
				dispatch(toggleModalProcessing(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Article Added");
				// redirect to article page
				history.push("/articles/" + results.new_article_id);
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new article ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editArticle = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateArticle(formData)
		.then( results => {
			fb_getArticle(formData.id)
			.then(article => {
				// push new data
				dispatch({ type: GET_ARTICLE, payload: { article } });	
				// updated
				dispatch({ type: EDIT_ARTICLE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Article Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteArticle = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteArticle(id)
		.then( results => {
			fb_getArticles(props)
			.then(list => {
				dispatch({ type: GET_ARTICLES, payload: { list } });	
				dispatch({ type: DELETE_ARTICLE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Article Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}