/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';

import { Disabled } from '../../styles/division';
import theme from '../../theme';

const useStyles = theme => ({
    paperScrollPaper: {
        background: "#fff"
    },
    paperScrollBody: {
        background: "#fff"
    }
});

class ModelView extends React.Component {


    componentDidUpdate(prevProps, prevState, snapshot) {
        const { modalProcessing, randNum, noForceClose } = this.props;
        if ( !modalProcessing && !randNum && prevProps && prevProps.modalProcessing && this.props.onClose ) {
            if ( !noForceClose )
                this.props.onClose();
        }
    }

    handleClose = () => {
        if ( this.props.onClose )
            this.props.onClose();
    }

    handleAction = () => {
        if ( this.props.doAction )
            this.props.doAction();
    }

    isProcessing = () => {
        const { processing, modalProcessing } = this.props;
        return ( processing || modalProcessing ? true : false );
    }

    renderActions = () => {
        const { actionLabel, noAction, cancelLabel, noCancel } = this.props;
        return (
        <DialogActions>
            { noCancel ? null : <Button onClick={this.handleClose} color="default">{( cancelLabel || 'Cancel' )}</Button> }
            { noAction ? null : <Button onClick={this.handleAction} color="primary" variant="contained">{actionLabel || 'Add New'}</Button> }
        </DialogActions>
        )
    }

    renderProcessing = () => {
        const { processingLabel } = this.props;
        return (
        <DialogActions>
            <Button color="primary"><CircularProgress size={14} color="primary" style={{ marginRight: "8px" }} />{processingLabel || 'Processing...'}</Button>
        </DialogActions>
        )
    }

    renderContent = () => {
        const { desc, contents } = this.props;
        return (
        <DialogContent>
            { desc && !_isEmpty( desc ) ? <DialogContentText id="alert-dialog-description">{desc}</DialogContentText> : '' }
            { contents && !_isEmpty( contents ) ? ( this.isProcessing() ? <Disabled opacity={0.75}>{contents}</Disabled> : contents ) : null }
        </DialogContent>
        )
    }

    renderTitle = () => {
        const { title, fullScreen, noTopX, cancelLabel } = this.props;
        return (
        <DialogTitle>
            { title && !_isEmpty( title ) ? title : "" }
            { fullScreen && !noTopX ? <IconButton 
                            style={{ padding: '6px', float: 'right' }}
                            aria-label={( cancelLabel || 'Cancel' )}
                            onClick={this.handleClose}><ClearIcon /></IconButton> : null }
        </DialogTitle>
        )
    }

    render() {
        const { open, title, content, classes, scroll, maxWidth, fullScreen, disableBackdrop, disableAutoFocus, disableEnforceFocus, customActions } = this.props;
        return <Dialog
            open={open}
            classes={classes}
            fullScreen={( fullScreen || null )}
            fullWidth={true}
            maxWidth={( maxWidth || 'sm' )}
            scroll={( scroll || 'body' )}
            disableBackdropClick={( this.isProcessing() || disableBackdrop ? true : false )}
            disableEscapeKeyDown={( this.isProcessing() || disableBackdrop ? true : false )}
            disableAutoFocus={( disableAutoFocus || false )}
            disableEnforceFocus={( disableEnforceFocus || false )}
            onClose={this.handleClose} >
            {this.renderTitle()}
            {this.renderContent()}
            { this.isProcessing() ? this.renderProcessing() : ( customActions ? customActions : this.renderActions() )}
      </Dialog>
    }

}

const mapStateToProps = state => {
    return {
        modalProcessing: state.global && state.global.modal_processing || null,
        randNum: state.global && state.global.modal_processing_rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ModelView);