/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import ModalView from '../../../components/ModalView';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import FormRadio from '../../../components/FormRadio';

import { FormBox } from '../../../styles/form';
import { InfoButton, GreyButton, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix, getSelectOptions } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightForm' );

const Wrapper = styled.div`
    
`;


const InsightForm = ({
    insight = {},
    folders = [],
    onUpdate
}) => {
    const { type = 'url', category_id = '', url = '', title = '', contents = [] } = insight;


    const handleUpdate = (newValue,key) => {
        onUpdate( update( insight, { [key] : { $set: newValue } } ) );
    }
    
    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <FormBox
            className={prefix+"FormBox"}>
            <div style={{ padding: '0px 0px 30px 0px' }}>
                <ButtonGroup disableElevation variant="contained">
                    <Button color={ type && type === 'url' ? 'primary' : null } onClick={() => handleUpdate('url','type')}>By URL</Button>
                    <Button color={ type && type === 'manual' ? 'primary' : null } onClick={() => handleUpdate('manual','type')}>Manual Input</Button>
                </ButtonGroup>
            </div>
            <div style={{ padding: '0px 0px 20px 0px' }}>
                <FormSelect
                    label="Folder *"
                    value={category_id}
                    name="category_id"
                    options={getSelectOptions({ list: ( folders || false ), options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                    onChange={handleUpdate} />
            </div>
            { type && type === 'url' ? (
            <>
                <FormInput
                    label="URL *"
                    value={url}
                    name="url"
                    onChange={handleUpdate} />
            </>
            ) : (
            <>
                <FormInput
                    label="Title *"
                    value={title}
                    name="title"
                    onChange={handleUpdate} />
                
            </>
            )}

        </FormBox>
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightForm);