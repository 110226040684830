import { combineReducers } from "redux";

import { auth } from "./auth";
import { global } from "./global";

import { articles } from "./articles";
import { insights } from "./insights";
import { notes } from "./notes";

import { users } from "./users";
import { media_library } from './media_library';
// import { maintenance } from './maintenance';
import { misc } from "./misc";

export default combineReducers({ 
    auth, 
    global,
    articles,
    insights,
    notes,
    
    users,
    media_library,
    // maintenance,
    misc
});