/* eslint-disable */
import React, { useState } from 'react';
import styled from "styled-components";
import MDEditor from '@uiw/react-md-editor';

import theme from '../../theme';

const Wrapper  = styled.div`
    display: block;
`;

const MarkdownEditor = (props) => { 
    const { name, value, onChange } = props;

    const handleUpdate = (newValue) => {
        if ( onChange )
            onChange( newValue, name );
    }

    return (
    <Wrapper>
        <MDEditor
            {...props}
            value={value}
            onChange={handleUpdate}
        />
    </Wrapper>
    )
}

export default MarkdownEditor