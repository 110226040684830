/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Paper from '@material-ui/core/Paper';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lightBlue from '@material-ui/core/colors/lightBlue';

import update from 'immutability-helper';
import styled from "styled-components";
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';


import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightEditor' );

const Wrapper = styled.div`
    display: block;

    h1 { font-size: 32px; }
    h2 { font-size: 24px; }
    h3 { font-size: 18px; }
    h4 { font-size: 16px; }
    h5 { font-size: 14px; }
    h6 { font-size: 12px; }

    h1, h2, h3, h4, h5, h6 { 
        margin: 30px 0 15px 0; 
        font-weight: bold;
    }

    p, ul, ol, blockquote { margin-bottom: 15px; }
`;

const BubbleMenuWrapper = styled.div`
    background: #444;
    border-radius: 6px;
    padding: 5px;

`;

const HighlightButton = styled.button`
    cursor: pointer;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    margin: 5px;
`;

const InsightEditor = ({
    content = '',
    showBubbleMenu = true,
    onUpdate
}) => {
    let extensions = [ StarterKit ];

    if ( showBubbleMenu ) {
        extensions.push( Highlight.configure({ 
            multicolor: true,
            HTMLAttributes: {
                class: 'highlighted',
            }
        }) );
    } // end - showBubbleMenu

    const editor = useEditor({
        content,
        extensions,
        onUpdate: ({ editor }) => {
            onUpdate(editor.getHTML(),editor)
        }
    },[ content ]);

    if ( !editor )
        return null;
    
    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        { showBubbleMenu ? (
        <BubbleMenu 
            editor={editor}>
            <Paper elevation={2}>
                <BubbleMenuWrapper>
                    <HighlightButton 
                        style={{ color: yellow['600'], backgroundColor: yellow['600'] }} 
                        onClick={() => editor.chain().focus().toggleHighlight({ color: yellow['600'] }).run()}>
                    </HighlightButton>
                    <HighlightButton 
                        style={{ color: red['200'], backgroundColor: red['200'] }} 
                        onClick={() => editor.chain().focus().toggleHighlight({ color: red['200'] }).run()}>
                    </HighlightButton>
                    <HighlightButton 
                        style={{ color: lightGreen['A200'], backgroundColor: lightGreen['A200'] }} 
                        onClick={() => editor.chain().focus().toggleHighlight({ color: lightGreen['A200'] }).run()}>
                        </HighlightButton>
                    <HighlightButton 
                        style={{ color: lightBlue['A100'], backgroundColor: lightBlue['A100'] }} 
                        onClick={() => editor.chain().focus().toggleHighlight({ color: lightBlue['A100'] }).run()}>
                    </HighlightButton>
                </BubbleMenuWrapper>
            </Paper>
        </BubbleMenu>
        ) : null }
        <EditorContent 
            editor={editor} />
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightEditor);