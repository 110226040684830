/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from '../../components/AppWrapper';
import Table from './table';

// import { cloneCollections } from '../../helpers/data';

import { getArticles } from '../../actions/articles';
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from '../../actions/misc';

class ArticlesPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get articles
        this.props.dispatch(getArticles());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { articlesList, randNum } = this.props;
        if ( articlesList && randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('articles'));
    }

    handleRefresh = (props) => {
        // get articles
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('articles'));
        this.props.dispatch(getArticles(props));
    }

    renderContents() {
        const { authData, articlesList } = this.props;
        return <Table
                articles={articlesList}
                authData={authData}
                onRefresh={this.handleRefresh} />;
    }

    render() {
        const { randNum } = this.state;
        const { articlesList, authData } = this.props;
        return <AppWrapper 
                title="Articles"
                onLoad={( !( randNum ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        articlesList: state.articles && state.articles.articles ? state.articles.articles : null,
        randNum: state.articles && state.articles.rand ? state.articles.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ArticlesPage);