/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';


import { callFunctionsAPI } from '../../../helpers/action';
import { isArrayExists } from '../../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../../helpers/schemas';

import { insightSchema } from '../../../schemas/insight';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */


export const fb_getInsights = ({ category_id }) => {
    return new Promise((resolve,reject) => {

        const Ref = firebase.database().ref("insights");

        if ( category_id && !_isEmpty( category_id ) && category_id !== 'all' ) {
            Ref = Ref.orderByChild("category_id").equalTo(category_id);
        }

        Ref.once("value")
        .then(snapshot => {
            let list = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren()  ) {
                snapshot.forEach(childSnapshot => {
                    let item = getSnapshotDataBySchema( insightSchema, childSnapshot );
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getInsightsOptions = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { project_id } = props;
        let Ref = firebase.firestore().collection("insights");

        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( insightSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}

export const fb_getInsightsCategories = () => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("insights_categories").once("value")
        .then(snapshot => {
            let list = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren()  ) {
                snapshot.forEach(childSnapshot => {
                    let item = childSnapshot.val();
                    item['id'] = childSnapshot.key;
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getInsight = (id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("insights").doc(id).get() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] ) {
                item = getDocDataBySchema( insightSchema, results[0] );
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_addInsight = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'insight', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateInsight = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'insight', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_triggerInsight = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'insight', action: 'trigger', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteInsight = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'insight', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}