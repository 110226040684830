/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styled from "styled-components";
import Alert from "react-s-alert";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _trim from 'lodash/trim';

import GetPromptButton from './button_get_prompt';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormEditor from '../../components/FormEditor';
import FormUpload from '../../components/FormUpload';
import ToolTipInfo from '../../components/ToolTipInfo';

import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';
import { AInfoLink, InfoButton, IndigoButton, ButtonGroup } from '../../styles/button';
import { GreyTag, InfoTag } from '../../styles/tag';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';


import { getPromptData, triggerTrainAction } from '../../actions/train';
import { toggleLoader } from '../../actions/global';
import { appChangesReset } from '../../actions/misc';

import { YES_NO_OPTIONS } from '../../constants';

const ContentTextareaWrapper = styled.div`
    .MuiOutlinedInput-multiline {
        padding: 5px;
        align-items: flex-start;
        font-size: 16px;
    }
    .MuiOutlinedInput-inputMultiline {
        padding: 30px;
        line-height:1.5;
    }

`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        // borderBottom: "1px solid #ddd"
    }
});

class EditForm extends React.Component {

    state = {
    };

    handleTypeEffects = (character) => {
        const { log } = this.props;
        let newContent = ( log && log.completion ? log.completion : '' ) + character;
        this.handleFormUpdate( newContent, 'completion' );
    }

    doTypingEffects = (newContent) => {
        for (let index = 0; index < _size(newContent); index++) {
            setTimeout(() => {
                this.handleTypeEffects(newContent.substring(index,index+1));
            }, 150);
        }
    }

    handleParaphraseAction = (event) => {
        const { log, onFormUpdate, tool } = this.props;
        event.preventDefault();
        let error = false;

        // do error check
        if ( !( log && log.prompt && !_isEmpty( log.prompt ) ) )
            errror = 'Please insert a prompt';
        
        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(toggleLoader(true));
            this.handleFormUpdate( '', 'completion' ); // remove exiting completion first
            triggerTrainAction({ prompt: log.prompt },'do_paraphrase')
            .then(data => {
                this.props.dispatch(toggleLoader(false));
                this.doTypingEffects(( data && data.choices && isArrayExists( data.choices ) && data.choices[0] && data.choices[0].text && !_isEmpty( data.choices[0].text ) ? data.choices[0].text : '' ));
                console.log( ( data && data.choices && isArrayExists( data.choices ) && data.choices[0] && data.choices[0].text && !_isEmpty( data.choices[0].text ) ? data.choices[0].text : '' ) );
            })
            .catch(error => {
                triggerErrorAlert(getAPIErrorMessage(error));
                this.props.dispatch(toggleLoader(false));
            });
        } // end - error

    }

    handleGenerateAction = (event) => {
        const { log, onFormUpdate, tool } = this.props;
        event.preventDefault();
        let error = false;

        // do error check
        if ( !( log && log.prompt && !_isEmpty( log.prompt ) ) )
            errror = 'Please insert a prompt';
        
        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(toggleLoader(true));
            this.handleFormUpdate( '', 'completion' ); // remove exiting completion first
            triggerTrainAction({ prompt: log.prompt },'train_' + tool)
            .then(data => {
                this.props.dispatch(toggleLoader(false));
                let completion = '';
                switch( tool ) {
                    case 'insert_summary':
                        completion = 'The author ';
                        break;
                }
                completion += ( data && data.choices && isArrayExists( data.choices ) && data.choices[0] && data.choices[0].text && !_isEmpty( data.choices[0].text ) ? _trim( data.choices[0].text ) : '' )
                this.handleFormUpdate( completion, 'completion' );
                console.log( completion );
                console.log(data.choices);
            })
            .catch(error => {
                triggerErrorAlert(getAPIErrorMessage(error));
                this.props.dispatch(toggleLoader(false));
            });
        } // end - error

    }

    handleRetrievePrompt = (event) => {
        event.preventDefault();
        this.props.dispatch(toggleLoader(true));
        getPromptData()
        .then(data => {
            this.props.dispatch(toggleLoader(false));
            if ( data && data.content && !_isEmpty( data.content ) ) {
                this.handleFormUpdate( data.content, 'prompt' );
            }
        })
        .catch(error => {
            triggerErrorAlert(getAPIErrorMessage(error));
            this.props.dispatch(toggleLoader(false));
        });
    }

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, log, currentCourse, course_type, course_duration, currency } = this.props;
        let newData = ( log && !_isEmpty( log ) ? cloneCollections( log ) : {} );

        switch( key ) {
            // case 'contents':
            //     newData[key] = turndownService.turndown(( newValue || '' ));
            //     break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    renderGetPromptButton = () => {
        const { tool, log, onDocDataUpdate } = this.props;
        switch(tool) {
            case 'insert_keypoints':
            case 'insert_summary':
                return (
                <ButtonGroup>
                    <GetPromptButton
                        tool={tool}
                        onUpdate={(newData) => {
                            this.handleFormUpdate( newData.id, 'id' );
                            this.handleFormUpdate( newData.prompt, 'prompt' );
                            if ( tool === 'insert_keypoints' ) {
                                this.handleFormUpdate( newData.kp_completion, 'completion' );
                            }
                            onDocDataUpdate(newData);
                        }} />
                    { log && log.id && !_isEmpty( log.id ) && (
                    <CopyToClipboard text={( log && log.id ? log.id : '' )}
                        onCopy={() => {
                            triggerSuccessAlert("ID copied to clipboard.");
                        }}>
                        <GreyTag style={{ cursor: 'pointer' }}>ID: {log.id}</GreyTag>
                    </CopyToClipboard>
                    ) }
                </ButtonGroup>
                )
            default:
                return <InfoButton size="small" onClick={this.handleRetrievePrompt}>Get Prompt</InfoButton>;
        }
    }

    renderPromptForm = () => {
        const { classes, log, docData } = this.props;
        return (
        <>
            <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
                <Grid item xs={12} sm={6}>
                    {this.renderGetPromptButton()}
                </Grid>
                <Grid item xs={12} sm={6} align="right">
                    <CopyToClipboard text={( log && log.prompt ? log.prompt : '' )}
                        onCopy={() => {
                            triggerSuccessAlert("Value copied to clipboard.");
                        }}>
                        <IndigoButton size="small">Copy</IndigoButton>
                    </CopyToClipboard>
                </Grid>
            </Grid>
            <FormBox>
                <FormInput
                    name="prompt"
                    label="Prompt"
                    rows={30} 
                    multiline={true} 
                    value={( log && log.prompt ? log.prompt : '' )} 
                    onChange={this.handleFormUpdate} />
                { docData && (
                <ButtonGroup style={{ marginTop: '10px' }}>
                    <InfoTag>Total Chars: {( log && log.prompt ? _size( log.prompt ) : 0 )}</InfoTag>
                    <InfoTag>Total Tokens: {( log && log.prompt ? (_size( log.prompt )/4).toFixed(0) : 0 )}</InfoTag>
                </ButtonGroup>  
                ) }
            </FormBox>
        </>
        )
    }

    renderCompletionButton = () => {
        const { tool } = this.props;
        switch(tool) {
            case 'paraphraser':
                return <InfoButton size="small" onClick={this.handleParaphraseAction}>Generate</InfoButton>;
            case 'insert_keypoints':
            case 'insert_summary':
                return <InfoButton size="small" onClick={this.handleGenerateAction}>Generate</InfoButton>;
        }
    }

    renderCompletionForm = () => {
        const { classes, log } = this.props;
        return (
        <>
            <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
                <Grid item xs={12} sm={6}>
                    
                </Grid>
                <Grid item xs={12} sm={6} align="right">
                    {this.renderCompletionButton()}
                </Grid>
            </Grid>
            <FormBox>
                <FormInput
                    name="completion"
                    label="Completion"
                    rows={30} 
                    multiline={true} 
                    value={( log && log.completion ? log.completion : '' )} 
                    onChange={this.handleFormUpdate} />
            </FormBox>
        </>
        )
    }

    render = () => {
        const { formType, classes, log, tool, onToolChange, onFormUpdate } = this.props;
        return (
        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", marginTop: '40px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormSelect
                        name="tool"
                        label="Tool"
                        value={( tool ? tool : 'paraphraser' )} 
                        options={[
                            { value: 'paraphraser', label: 'Paraphraser' },
                            { value: 'insert_keypoints', label: 'Content > keypoints' },
                            { value: 'insert_summary', label: 'Content > keypoints > Summary' },
                        ]}
                        onChange={(newValue) => {
                            switch(newValue) {
                                case 'insert_keypoints':
                                    onToolChange(newValue);
                                    onFormUpdate({ prompt: `Title: 

Content:

`, completion: '' });
                                    break;
                                default:
                                    onToolChange(newValue);
                                    break;
                            }
                        }} />
                </Grid>
                <Grid item xs={12} sm={6}> </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: '20px' }}>
                    {this.renderPromptForm()}
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: '20px' }}>
                    {this.renderCompletionForm()}
                </Grid>
            </Grid>
        </Paper>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(EditForm);