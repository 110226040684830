/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from 'firebase/app';
import update from 'immutability-helper';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _toString from 'lodash/toString';
import _random from 'lodash/random';

import Table from './table';

import AppWrapper from '../../components/AppWrapper';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getAPIErrorMessage } from '../../helpers/action';

const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const getCategories = () => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("z_akimbo_fr6_categories").once("value")
        .then( snapshot => {
            let list = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    list.push( childSnapshot.val() );
                })
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

const getPosts = ({ perPage = 5, category_id = false, order = 'id', orderBy = 'desc', startAfter = false }) => {
    return new Promise((resolve,reject) => {

        // let Ref = firebase.firestore().collection('z_akimbo_fr6_posts');
        
        // if ( category_id )
        //     Ref = Ref.where("category_id",'==',_toString( category_id ));

        // if ( order && !_isEmpty( order ) && orderBy && !_isEmpty( orderBy ) )
        //     Ref = Ref.orderBy( order, orderBy );

        // if ( startAfter )
        //     Ref = Ref.startAfter(startAfter);

        // if ( perPage )
        //     Ref = Ref.limit(perPage);

        // Ref.get()
        // .then( snapshot => {
        //     let list = [];
        //     if ( snapshot ) {
        //         snapshot.forEach(doc => {
        //             list.push(doc.data());
        //         });
        //     } // end - snapshot
        //     resolve(list);
        // })
        // .catch(error => {
        //     reject(error);
        // });

        firebase.database().ref("z_akimbo_fr6_posts").once("value")
        .then( snapshot => {
            let list = [];
            if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    list.push( childSnapshot.val() );
                })
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}


const FR6PostsPage = ({
    authData,
    history
}) => {
    const [ randNum, setRandNum ] = useState(false);
    const [ posts, setPosts ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ perPage, setPerPage ] = useState(3);
    const [ category_id, setCategoryID ] = useState(false);

    useEffect(() => {
        let promises = [];

        promises.push( getPosts({ 
            perPage, 
            category_id 
        }) );

        promises.push( getCategories() );
        
        Promise.all( promises )
        .then(results => {
            setPosts( update(posts, { $merge: ( results && results[0] || [] ) }) );
            setCategories( update(categories, { $merge: ( results && results[1] || [] ) }) );
            setRandNum( _random(1,9999) );
        })
        .catch(error => {
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    },[]);
    
    return <AppWrapper 
            title="FR6 Posts"
            onLoad={( !( randNum ) ? true : false )}
            contents={(
            <>
                <Table
                    list={posts}
                    categories={categories}
                    authData={authData}
                     />
            </>
            )} />;

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(FR6PostsPage);