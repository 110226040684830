/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import update from 'immutability-helper';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import Folders from './parts/folders';
import Cards from './parts/cards';
import CardsTopBar from './parts/cards_topbar';
import AddNewNote from './parts/addnew';

import AppWrapper from '../../components/AppWrapper';
import ModelDelete from '../../components/ModalDelete';

import { cloneCollections, getClassPrefix, doArraySearch } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert} from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { fb_getInsights, fb_getInsightsCategories, fb_addInsight, fb_deleteInsight } from './api/api';
import { toggleLoader } from '../../actions/global';
// import { resetRedux } from '../../actions/misc';

const prefix = getClassPrefix( 'modules', 'Insights' );

const Wrapper = styled.div`
    .${getClassPrefix('components','AppWrapper')}MainContentWrapper {
        padding-bottom: 0px;
    }
    .${getClassPrefix('components','AppWrapper')}ContentOuterWrapper {
        padding: 0px;
    }
    .${getClassPrefix('components','AppWrapper')}ContentWrapper {
        padding: 0px;
    }
    .${getClassPrefix('components','AppWrapper')}TopBarHeight {
        height: 0px;
    }
`;

const CardsWrapper = styled.div`
    height: 100%;
    padding: 20px;
    min-height: 100vh;
`;

const PageContentWrapper = styled.div`
    background: #f1f1f1;
    min-height: 100vh;
`;

const Insights = ({
    authData,
    history,
    dispatch
}) => {
    const [ loaded, setLoaded ] = useState( false );
    const [ insights, setInsights ] = useState( [] );
    const [ insightCategories, setInsightCategories ] = useState( [] );
    const [ selectedFolder, setSelectedFolder ] = useState( 'all' );
    const [ deleteModalOpen, setDeleteModalOpen ] = useState( false );
    const [ newCardModalOpen, setNewCardModalOpen ] = useState( false );
    const [ deleteModal, setDeleteModal ] = useState( false );
    const [ searchTerm, setSearchTerm ] = useState( '' );

    useEffect(() => {
        let mounted = true,
            promises = [];

        promises.push( fb_getInsights({}) );
        promises.push( fb_getInsightsCategories() );

        Promise.all( promises )
        .then( ( [insights, insightCategories] ) => {
            if ( mounted ) {
                setLoaded( true );
                setInsights( insights );
                setInsightCategories( insightCategories );
            }
        })
        .catch( error => {
            triggerErrorAlert( getAPIErrorMessage(error) );
        });

        return () => {
            // reset
            mounted = false;
            // dispatch(resetRedux('insights'));
        }
    },[]);

    const getNotes = () => {
        let notes = cloneCollections( insights );
        if ( selectedFolder !== 'all' ) {
            notes = _filter( notes, { category_id: selectedFolder } );
        }
        if ( searchTerm !== '' ) {
            notes = doArraySearch( notes, searchTerm, ['title'] );
        }
        return notes;
    }

    const deleteInsight = () => {
        dispatch( toggleLoader(true) );
        fb_deleteInsight( deleteModal )
        .then(() => {
            // retrieve new list
            return fb_getInsights({});
        })
        .then(newInsights => {
            dispatch( toggleLoader(false) );
            setDeleteModal(false);
            setDeleteModalOpen(false);
            setInsights( newInsights );
            triggerSuccessAlert("Insight Deleted");
        })
        .catch( (error) => {
            dispatch( toggleLoader(false) );
            setDeleteModal(false);
            setDeleteModalOpen(false);
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
    }

    const handleAddNewNote = (data) => {
        dispatch( toggleLoader(true) );
        fb_addInsight( data )
        .then( ({new_insight_id}) => {
            dispatch( toggleLoader(false) );
            history.push( `/insights/${new_insight_id}` );
        })
        .catch( (error) => {
            dispatch( toggleLoader(false) );
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
    }

    const handleInsightRemove = (id) => {
        setDeleteModalOpen(true);
        setDeleteModal(id);
    }

    const handleInsightRead = (id) => {
        history.push("/insights/"+id);
    }

    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <AppWrapper 
            title=""
            maxWidth="100%"
            contentWrapperStyle={{ paddingTop: '0px' }}
            onLoad={ !loaded }
            contents={ authData ? (
            <>
                <PageContentWrapper>
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                            <Folders
                                folders={insightCategories}
                                selectedFolder={selectedFolder}
                                onFolderChange={(newFolder) => {
                                    setSelectedFolder( newFolder );
                                }} />
                        </Grid>
                        <Grid item xs={10}>
                            <CardsWrapper
                                className={prefix+"CardsWrapper"}>
                                <CardsTopBar
                                    searchterm={searchTerm}
                                    onSearchtermChange={(newSearchTerm) => setSearchTerm( newSearchTerm )}
                                    onAddNew={() => setNewCardModalOpen(true)} />
                                <Cards
                                    notes={getNotes()}
                                    folders={insightCategories}
                                    onInsightRead={handleInsightRead}
                                    onInsightDelete={handleInsightRemove}
                                    />
                            </CardsWrapper>
                        </Grid>
                    </Grid>
                </PageContentWrapper>

                <AddNewNote
                    open={newCardModalOpen}
                    folders={insightCategories}
                    selectedFolder={selectedFolder}
                    onAddNew={handleAddNewNote}
                    onClose={() => setNewCardModalOpen(false)}
                    />

                <ModelDelete
                    open={deleteModalOpen}
                    title={( deleteModal && deleteModal.title ? `Are you sure you want to delete this insight ( ${deleteModal.title} )?` : false )}
                    onClose={() => {
                        setDeleteModalOpen( false );
                        setDeleteModal(false);
                    }}
                    onDelete={deleteInsight} />

            </>
            ) : null } />
    </Wrapper>
    );
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        // insightsList: state.insights && state.insights.insights ? state.insights.insights : null,
        // insightsCategoryList: state.insights && state.insights.categories ? state.insights.categories : null,
        // randNum: state.insights && state.insights.rand ? state.insights.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Insights);