/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import indigo from '@material-ui/core/colors/indigo';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

import theme from '../theme';

const buttonStyle = `
    display: inline-block;
    font-size: 1.35rem;
    background-color: ${theme.palette.primary.main};
    border-width: 0;
    border-color: ${theme.palette.primary.dark};
    border-style: solid;
    color: ${theme.palette.primary.contrastText};
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    transition: all 0.35s ease-in-out;

    i {
        margin-right: 8px;
    }

    &:hover {
        background: ${theme.palette.primary.dark}
    }
`;

const Info = `
    background: ${blue['700']};
    color: ${grey['50']};

    &:hover {
        background: ${blue['500']};
    }
`;

const Success = `
    background: ${green['700']};
    color: ${grey['50']};

    &:hover {
        background: ${green['500']};
    }
`;

const Warning = `
    background: ${amber['700']};
    color: ${grey['50']};

    &:hover {
        background: ${amber['500']};
    }
`;

const ErrorColor = `
    background: ${red['700']};
    color: ${grey['50']};

    &:hover {
        background: ${red['500']};
    }
`;

const Indigo = `
    background: ${indigo['700']};
    color: ${grey['50']};

    &:hover {
        background: ${indigo['500']};
    }
`;

const Inverse = `
    background: ${grey['900']};
    color: ${grey['50']};

    &:hover {
        background: ${grey['600']};
        color: ${grey['50']};
    }
`;

const Grey = `
    background: ${grey['300']};
    color: ${grey['700']};

    &:hover {
        background: ${grey['800']};
        color: ${grey['50']};
    }
`;

const White = `
    background: ${grey['50']};
    color: ${grey['900']};

    &:hover {
        background: ${theme.palette.primary.main};
        color: ${grey['50']};
    }
`;

export const Button = styled.button`
    ${buttonStyle}
    min-width: ${props => (props.minWidth ? props.minWidth : "64px")};
    ${props => {
        if ( props.size && props.size == 'small' ) {
            return `
            padding: 5px 8px;
            font-size: 1.15rem;
            i { margin-right: 5px; }
            `;
        }
    }}
    ${props => {
        if ( props.noIconMargin && props.noIconMargin == 'yes' ) {
            return `
            i { margin: 0px; }
            `;
        }
    }}
    ${props => {
        if ( props.rightIcon && props.rightIcon == 'yes' ) {
            return `
            i { margin: 0px 0px 0px 8px; }
            `;
        }
    }}
    ${props => {
        if ( props.disabled && props.disabled == 'yes' ) {
            return `
            opacity: 0.65;
            cursor: default;
            pointer-events: none;
            `;
        }
    }}
`;

export const InfoButton = styled(Button)`
    ${Info} 
`;

export const SuccessButton = styled(Button)`
    ${Success}  
`;

export const WarningButton = styled(Button)`
    ${Warning}  
`;

export const ErrorButton = styled(Button)`
    ${ErrorColor}  
`;

export const InverseButton = styled(Button)`  
    ${Inverse}
`;

export const IndigoButton = styled(Button)`  
    ${Indigo}
`;

export const GreyButton = styled(Button)`
    ${Grey}
`;

export const WhiteButton = styled(Button)`
    ${White}  
`;

// button in a

export const ALink = styled.a`
    ${buttonStyle}
    min-width: ${props => (props.minWidth ? props.minWidth : "64px")};
    ${props => {
        if ( props.size && props.size == 'small' ) {
            return `
            padding: 5px 8px;
            font-size: 1.15rem;
            i { margin-right: 5px; }
            `;
        }
    }}
    ${props => {
        if ( props.noIconMargin && props.noIconMargin == 'yes' ) {
            return `
            i { margin: 0px; }
            `;
        }
    }}
    ${props => {
        if ( props.rightIcon && props.rightIcon == 'yes' ) {
            return `
            i { margin: 0px 0px 0px 8px; }
            `;
        }
    }}
    ${props => {
        if ( props.disabled && props.disabled == 'yes' ) {
            return `
            opacity: 0.65;
            cursor: default;
            pointer-events: none;
            `;
        }
    }}
`;

export const AInfoLink = styled(ALink)`
    ${Info} 
`;

export const ASuccessLink = styled(ALink)`
    ${Success}
`;

export const AWarningLink = styled(ALink)`
    ${Warning}
`;

export const AErrorLink = styled(ALink)`
    ${ErrorColor}
`;

export const AIndigoLink = styled(ALink)`
    ${Indigo}
`;

export const AInverseLink = styled(ALink)`
    ${Inverse}
`;

export const AWhiteLink = styled(ALink)`
    ${White}
`;

// link

export const InfoLink = styled(Button)`
    background: none;
    color: ${blue['700']};

    &:hover {
        color: ${blue['500']};
        background: none;
    }
`;

export const SuccessLink = styled(Button)`
    background: none;
    color: ${green['700']};

    &:hover {
        color: ${green['500']};
        background: none;
    }
`;

export const WarningLink = styled(Button)`
    background: none;
    color: ${amber['700']};

    &:hover {
        color: ${amber['500']};
        background: none;
    }
`;


export const IndigoLink = styled(Button)`
    background: none;
    color: ${indigo['700']};

    &:hover {
        color: ${indigo['500']};
        background: none;
    }
`;

export const ErrorLink = styled(Button)`
    background: none;
    color: ${red['700']};

    &:hover {
        color: ${red['500']};
        background: none;
    }
`;

export const InverseLink = styled(Button)`
    background: none;
    color: ${grey['900']};

    &:hover {
        color: ${grey['700']};
        background: none;
    }
`;

export const GreyLink = styled(Button)`
    background: none;
    color: ${grey['500']};

    &:hover {
        color: ${grey['700']};
        background: none;
    }
`;

/* button group */
export const ButtonGroup = styled.div`
    & > button,
    & > a {
        margin-right: 5px;
        margin-bottom: 5px;

        &:last-child() {
            margin-right: 0px;
        }
    }
    ${props => {
        if ( props.align && ( props.align === 'left' || props.align === 'right' ) ) {
            return `
            text-align: ${props.align};
            `;
        }
    }}
`;