/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import ToolTipInfo from '../ToolTipInfo';

import { isArrayExists } from '../../helpers/validation';

const useStyles = theme => ({
    formcontrol: {
        background: '#fff',
        
    },
    label: {
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: '14px',
        paddingBottom: '5px'
    },
    radio: {
        padding: '5px 9px'
    }
});

class FormRadio extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    renderTooltipInfo = (tooltip) => {
        return <span style={{ paddingLeft: '5px' }}><ToolTipInfo content={( tooltip || false )} /></span>;
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { id, value, options, name, disabled, classes, inline, radioIcon, radioCheckedIcon } = this.props;
        var newID = 'radio-field-' + _random(1,9999);
        return (
        <FormControl id={( id || newID )} variant="outlined" fullWidth={true} className={classes.formcontrol} >
            <FormLabel component="legend" className={classes.label}>{this.renderLabel()}</FormLabel>
            <RadioGroup value={value} onChange={this.handleChange} row={( inline ? true : false )}>
                { options && isArrayExists( options ) ? options.map(option => {
                    return <FormControlLabel 
                        disabled={( disabled || false )}
                        key={option.value} 
                        value={option.value} 
                        color="primary"
                        control={<Radio 
                            checkedIcon={( radioCheckedIcon || <RadioButtonCheckedIcon /> )}
                            icon={( radioIcon || <RadioButtonUncheckedIcon /> )}
                            className={classes.radio} />} 
                        label={(
                        <>
                            {option.label}
                            { option.tooltip && !_isEmpty( option.tooltip ) ? this.renderTooltipInfo(option.tooltip) : null }
                        </>
                        )} />
                }) : null }
            </RadioGroup>
        </FormControl>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(FormRadio);