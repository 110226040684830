/* eslint-disable */
import { api_env } from './env';
import { 
    FIREBASE_ACCOUNT_ID, 
	FIREBASE_REGION,
    FIREBASE_FUNCTIONS_REGION,
    USE_EMULATOR,
    EMULATOR_PORT_CLOUD_FUNCTIONS,
	FIREBASE_CONFIG_DATA
    // EMULATOR_PORT_FIRESTORE,
    // EMULATOR_PORT_DATABASE 
} from './firebase';

//*incomplete - recheck don't need this anymore

export const DEV_MODE = ( api_env && api_env === 'live' ? false : true );

// platform related
export const PLATFORM_NAME = 'SAAI';
export const PLATFORM_NAME_LONG = 'Smashing Advantage AI';
export const PLATFORM_ORG_NAME = 'Smashing Advantage';
export const PLATFORM_COMPANY_NAME = 'Smashing Advantage Enterprise';
export const PLATFORM_ORG_LOGO = '/assets/images/site_logo.png';
export const PLATFORM_URL = 'https://saai.smashingadvantage.com/';
export const PLATFORM_WEBSITE_URL = 'https://www.smashingadvantage.com/';
export const PLATFORM_ID = 'saai';
export const PLATFORM_EMAIL = '';
export const PLATFORM_PHONE = '';
export const PLATFORM_STORAGE_URL = 'https://firebasestorage.googleapis.com/v0/b/'+FIREBASE_ACCOUNT_ID+'.appspot.com/o/';

export const PLATFORM_ADMIN_DEFAULT_REGION = '';

// API related
export const AUTH_TOKEN = PLATFORM_ID + 'Token';

// firebase related
export const FIREBASE_API = FIREBASE_CONFIG_DATA.databaseURL;
export const FIREBASE_CLOUD_API = ( DEV_MODE ? 'http://localhost:'+( USE_EMULATOR ? EMULATOR_PORT_CLOUD_FUNCTIONS : '5000' )+'/'+FIREBASE_ACCOUNT_ID+'/'+FIREBASE_FUNCTIONS_REGION+'/' : 'https://'+FIREBASE_FUNCTIONS_REGION+'-'+FIREBASE_ACCOUNT_ID+'.cloudfunctions.net/' );
export const FIRESTORE_DEFAULT_PERPAGE = ( api_env && api_env === 'dev' ? 4 : 20 );

// Limits
export const PROFILE_IMAGE_LIMIT = 1048576;

// responsive 
export const HIDE_DRAWER_WIDTH = 1200;

// options
export const ENTRIES_OPTIONS = [
	{ value: 5, label: '5' },
	{ value: 20, label: '20' },
	{ value: 40, label: '40' },	
	{ value: 60, label: '60' },
	{ value: 80, label: '80' },
	{ value: 100, label: '100' },
	{ value: 200, label: '200' },
	{ value: 500, label: '500' }
];

export const MONTH_OPTIONS = [
	{ value: "0", label: 'Jan', maxDate: 31 },
	{ value: "1", label: 'Feb', maxDate: 28 },
	{ value: "2", label: 'Mar', maxDate: 31 },
	{ value: "3", label: 'Apr', maxDate: 30 },
	{ value: "4", label: 'May', maxDate: 31 },
	{ value: "5", label: 'Jun', maxDate: 30 },
	{ value: "6", label: 'Jul', maxDate: 31 },
	{ value: "7", label: 'Aug', maxDate: 31 },
	{ value: "8", label: 'Sep', maxDate: 30 },
	{ value: "9", label: 'Oct', maxDate: 31 },
	{ value: "10", label: 'Nov', maxDate: 30 },
	{ value: "11", label: 'Dec', maxDate: 31 }
];

export const DAY_OPTIONS = [
	{ value: 'mon', label: 'Monday', number: 1 },
	{ value: 'tue', label: 'Tuesday', number: 2 },
	{ value: 'wed', label: 'Wednesday', number: 3 },
	{ value: 'thu', label: 'Thursday', number: 4 },
	{ value: 'fri', label: 'Friday', number: 5 },
	{ value: 'sat', label: 'Saturday', number: 6 },
	{ value: 'sun', label: 'Sunday', number: 7 },
];

export const GENDER_OPTIONS = [{ value: '', label: 'Select Gender' },{ value: 'male', label: 'Male' },{ value: 'female', label: 'Female' }];
export const YES_NO_OPTIONS = [{ value: 'yes', label: 'Yes' },{ value: 'no', label: 'No' }];
export const PLACEHOLDER_OPTIONS = [{ value: 'option-1', label: 'Option 1' },{ value: 'option-2', label: 'Option 2' },{ value: 'option-3', label: 'Option 3' }];

// access rights
// list of access rights
export const ACCESS_RIGHTS = [
	{ id: 'atr', label: 'Article (Read)', width: 4 },
    { id: 'atw', label: 'Article (Create)', width: 4 },
	{ id: 'atm', label: 'Article (Update)', width: 4 },
	{ id: 'atd', label: 'Article (Delete)', width: 12 },
	{ id: 'isr', label: 'Insight (Read)', width: 4 },
    { id: 'isw', label: 'Insight (Create)', width: 4 },
	{ id: 'ism', label: 'Insight (Update)', width: 4 },
	{ id: 'isd', label: 'Insight (Delete)', width: 12 },
	{ id: 'cdr', label: 'Code (Read)', width: 4 },
    { id: 'cdw', label: 'Code (Create)', width: 4 },
	{ id: 'cdm', label: 'Code (Update)', width: 4 },
	{ id: 'cdd', label: 'Code (Delete)', width: 12 },
	{ id: 'ntr', label: 'Notes (Read)', width: 4 },
    { id: 'ntw', label: 'Notes (Create)', width: 4 },
	{ id: 'ntm', label: 'Notes (Update)', width: 4 },
	{ id: 'ntd', label: 'Notes (Delete)', width: 12 },
	{ id: 'wrw', label: 'Write API', width: 12 },
];


