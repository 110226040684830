export const userSchema = [
    { 
        id: 'uid', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add','update']
    },
    {   
        id: 'status',
        label: 'Status',
        required: false,
        default: 'pending',
        type: 'string',
        field: 'select',
        skip: ['add'],
        disabled: ['self']
    },
    { 
        id: 'region', 
        label: 'Organization',
        required: ['add','update'], 
        default: '', 
        type: 'string', 
        field: 'select',
        skip: ['add','update'],
        disabled: ['admin']
    },
    {   
        id: 'name',
        label: 'Name',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false
    },
    {   
        id: 'email',
        label: 'Email',
        required: ['add','update'],
        default: '',
        type: 'email',
        field: 'email',
        skip: ['add','update'],
        disabled: ['update']
    },
    {   
        id: 'role',
        label: 'Role',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: ['add','update']
    },
    {   
        id: 'org',
        label: 'Company Name',
        required: false,
        default: '',
        type: 'string',
        field: 'text'
    },
];