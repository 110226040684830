/* eslint-disable */
import _isEmpty from 'lodash/isEmpty';
// import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
// import _split from 'lodash/split';

// import { isArrayExists } from './validation';

// check if current user is admin
export const isAdmin = function(authData) {
    return ( authData && authData.type && ( authData.type === 'superadmin' ) ? true : false );
}

// check if current user is admin
export const isAdminType = function(authData) {
    return ( authData && authData.type && ( authData.type === 'admin' || authData.type === 'superadmin' ) ? true : false );
}

// check if current user is user
export const isUserType = function(authData) {
    return ( authData && authData.type && authData.type === 'user' ? true : false );
}

// check if current user is mentor
export const isMentorRole = function(authData) {
    return ( authData && authData.type && authData.type === 'user' && authData.role && authData.role === 'mentor' ? true : false );
}

// check if current user have the access rights
export const hasAccessRights = function(authData,accessRights) {
    return ( authData && !_isEmpty( authData ) && (
        ( authData.type === 'superadmin' || !accessRights ) || 
        ( accessRights && authData.accesses && !_isEmpty( authData.accesses ) && _find( accessRights, (r) => authData.accesses.indexOf(r) >= 0 ) )
    ) ? true : false );
}

// get current user region
export const getUserRegionFilter = (authData,all) => {
    let selectedRegion = ( authData && authData.region && !_isEmpty( authData.region ) ? authData.region : '' );
    if ( all ) {
        return selectedRegion;
    } else {
        return ( isAdmin( authData ) ? false : selectedRegion );
    } // end - all
}