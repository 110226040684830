/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Alert from "react-s-alert";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _size from 'lodash/size';

import SemanticSearchResults from './results';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormEditor from '../../components/FormEditor';
import FormUpload from '../../components/FormUpload';
import ToolTipInfo from '../../components/ToolTipInfo';

import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';
import { AInfoLink, InfoButton, IndigoButton, InverseButton } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { notesSchema } from '../../schemas/notes';

import { triggerWriteAction } from '../../actions/write';
import { toggleLoader } from '../../actions/global';

import { YES_NO_OPTIONS } from '../../constants';

const ContentTextareaWrapper = styled.div`
    .MuiOutlinedInput-multiline {
        padding: 5px;
        align-items: flex-start;
        font-size: 16px;
    }
    .MuiOutlinedInput-inputMultiline {
        padding: 30px;
        line-height:1.5;
    }

`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        // borderBottom: "1px solid #ddd"
    }
});

class EditForm extends React.Component {

    state = {
        processing: false,
        query_options: '',
        display_option: 'all',
        show_score: 'yes',
        openResultsModal: false,
        results: false
    };

    handleTypeEffects = (character) => {
        const { notes } = this.props;
        let newContent = ( notes && notes.contents ? notes.contents : '' ) + character;
        this.handleFormUpdate( newContent, 'contents' );
    }

    handleWriteAction = () => {
        const { notes, onFormUpdate } = this.props;
        const { length } = this.state;
        let error = false,
            context = ( notes.contents || '' );

        // do error check
        if ( !( notes.query && !_isEmpty( notes.query ) ) )
            error = 'Please select a query';
        
        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.setState({ processing: true });
            // real trigger
            triggerWriteAction({
                context,
                length,
                query: ( notes && notes.query ? notes.query : '' )
            },( notes && notes.action ? notes.action : '' ))
            .then(data => {
                console.log( data );
                this.setState({ processing: false });
                this.handleFormUpdate( ( data && data.list && isArrayExists( data.list ) ? data.list : [] ), 'results' );
                this.handleFormUpdate( ( data && data.average ? data.average : 0 ), 'average' );
            })
            .catch(error => {
                triggerErrorAlert(getAPIErrorMessage(error));
                this.setState({ processing: false });
            });
        } // end - error

    }

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, notes, currentCourse, course_type, course_duration, currency } = this.props;
        let newData = ( notes && !_isEmpty( notes ) ? cloneCollections( notes ) : {} );

        switch( key ) {
            // case 'contents':
            //     newData[key] = turndownService.turndown(( newValue || '' ));
            //     break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getActionBtnLabel = () => {
        const { notes } = this.props;
        if ( notes && notes.action ) {
            switch( notes.action ) {
                case 'semantic_search':
                    return 'Compile Now';
                case 'code_for_me':
                    return 'Code Now';
                default:
                    return 'Perform';
            }
        } else {
            return 'Perform';
        }
    }

    getFieldOptions = (field) => {
        const { notes } = this.props;

        switch( field.name ) {
            // case 'course_type':
            //     return getSelectOptions({ list: ( course_type || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'publish':
                return YES_NO_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { notes } = this.props;
        switch( field.name ) {
            default:
                return ( notes && notes[field.name] ? notes[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getFieldHeight = (field) => {
        switch( field.name ) {
            case 'price_per_pax':
                return 300;
            default:
                return 500;
        }
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-notes-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( notesSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.id === 'publish' ? true : ( schema.disabled ? this.isFieldDisabled( schema ) : false ) ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        const { authData, notes, dispatch } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 12 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={true} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'tinymce_editor':
                    return <FormEditor id={id + '-' + ( notes && notes.id && !_isEmpty( notes.id ) ? notes.id : '' )} {...field} value={this.getFieldValue(field)} height={this.getFieldHeight(field)} onChange={this.handleFormUpdate} filePickerTypes= 'image' filePickerCallback={filePickerCallBack(authData,notes,field,dispatch)} />;
            }
        } // end - field.field_type
    }

    renderCreatedModifiedDate = (item) => {
        let created_on = ( item.created_on && item.created_on._seconds ? item.created_on._seconds*1000 : ( item.created_on || null ) ),
            modified_on = ( item.modified_on && item.modified_on._seconds ? item.modified_on._seconds*1000 : ( item.modified_on || null ) );
        return (
        <div style={{ paddingTop: "15px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
            <div>{ created_on ? 'Created on ' + getMomentTime( created_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            <div>{ modified_on ? 'Last Modified on ' + getMomentTime( modified_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
        </div>
        )
    }

    renderResults = () => {
        const { notes } = this.props;
        const { display_option, show_score } = this.state;
        return <SemanticSearchResults 
                    showAverageScore="yes"
                    notes={( notes || false )}
                    display_option={( display_option || false)}
                    show_score={( show_score || false )} />
    }

    renderResultsOptions = () => {
        const { display_option, show_score } = this.state;
        return (
        <>
            <Divider style={{ marginTop: '30px', marginBottom: '30px' }} />
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <FormRadio
                        label="Display Option"
                        name='query_options'
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'above-average', label: 'Above Average Only' },
                            { value: 'top-25', label: 'Top 25%' },
                            { value: 'top-10', label: 'Top 10%' },
                        ]}
                        value={( display_option ? display_option : '' )} 
                        onChange={(newValue) => this.setState({ display_option: newValue })} />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <FormRadio
                        label="Show Score"
                        name='show_score'
                        options={[
                            { value: 'yes', label: 'Yes' },
                            { value: 'no', label: 'No' },
                        ]}
                        value={( show_score ? show_score : '' )} 
                        onChange={(newValue) => this.setState({ show_score: newValue })} />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '15px' }}>
                    <InverseButton 
                        onClick={() => {
                            this.handleFormUpdate( [], 'results' );
                        }} 
                        style={{ width: '100%', textAlign: 'center', padding: '10px', borderRadius: '6px' }}>
                        <i className="fa fa-refresh"></i>Reset
                    </InverseButton>
                </Grid>
            </Grid>
        </>
        )
    }

    renderPlaygroundForm = () => {
        const { classes, notes } = this.props;
        const { processing, query_options } = this.state;
        return (
        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", minHeight: '100vh' }}>
            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>Playground</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <FormSelect
                            label="Pre-load Query"
                            name='query_options'
                            options={[
                                { value: 'insights', label: 'Insights' },
                                { value: 'historic study', label: 'Historic Study' },
                            ]}
                            disableNative={true}
                            value={( query_options ? query_options : '' )} 
                            onChange={(newValue) => {
                                if ( newValue && newValue === 'custom' ) {
                                   this.handleFormUpdate('','query'); 
                                } else {
                                    this.handleFormUpdate(newValue,'query');
                                }
                                this.setState({ query_options: newValue });
                            }} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <FormInput
                            name="query"
                            label="Query"
                            rows={3} 
                            multiline={true} 
                            value={( notes && notes.query ? notes.query : '' )} 
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '30px' }}>
                        <IndigoButton 
                            disabled={( processing ? 'yes' : null )}
                            onClick={this.handleWriteAction} 
                            style={{ width: '100%', textAlign: 'center', padding: '10px', borderRadius: '6px' }} 
                            rightIcon={processing ? null :"yes"}>
                                { processing ? null : this.getActionBtnLabel() }
                                <i className={"fa "+( processing ? "fa-spinner fa-pulse" : "fa-long-arrow-right" )}></i>
                            </IndigoButton>
                    </Grid>
                </Grid>
                { notes && notes.results && isArrayExists( notes.results ) ? this.renderResultsOptions() : null }
            </FormBox>
        </Paper>
        )
    }

    renderContentForm = () => {
        const { classes, notes } = this.props;
        const { processing } = this.state;
        return (
        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", minHeight: '100vh' }}>
            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>Content</Typography>
                {this.renderField('title')}
                <div style={{ paddingTop: '20px' }}>
                    { notes && notes.results && isArrayExists( notes.results ) ? this.renderResults() : (
                    <ContentTextareaWrapper>
                        <FormInput
                            id="main_content_area"
                            name="contents"
                            label="Contents"
                            rows={30} 
                            multiline={true} 
                            value={( notes && notes.contents ? notes.contents : '' )} 
                            onChange={this.handleFormUpdate} />
                    </ContentTextareaWrapper>
                    )}
                </div>  
            </FormBox>
        </Paper>
        )
    }

    render = () => {
        const { formType, classes, notes, disableSystemDate } = this.props;
        const { openResultsModal, results } = this.state;
        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    {this.renderContentForm()}
                </Grid>
                <Grid item xs={4}>
                    {this.renderPlaygroundForm()}
                </Grid>
            </Grid>

            {/* { disableSystemDate ? null : this.renderCreatedModifiedDate(notes)} */}
        </>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(EditForm);