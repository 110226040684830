import _random from 'lodash/random';

import { 
	GET_NOTES_LIST,
    GET_NOTES_OPTIONS,
    GET_NOTES,
	ADD_NOTES,
	EDIT_NOTES,
	DELETE_NOTES,
    RESET_NOTES,
    RESET_NOTES_LIST
} from '../actions/types';

const initialState = {
    list: null,
    notes: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const notes = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_NOTES_LIST:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_NOTES_OPTIONS:
            return {
                ...state,
                articles_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case GET_NOTES:
            return {
                ...state,
                notes: ( payload.notes || {} ),
                rand: _random(1,9999)
            }; 
        case ADD_NOTES:
        case EDIT_NOTES:
        case DELETE_NOTES:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_NOTES:
            return {
                ...state,
                notes: null,
                updated: false,
                rand: false
            };
        case RESET_NOTES_LIST:
            return {
                ...state,
                articles: null,
                rand: false
            };
        default:
            return state;
    }
}