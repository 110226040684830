/* eslint-disable */
import React, { useState, useMemo } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import TurndownService from 'turndown';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import _forEach from 'lodash/forEach';
// import _size from 'lodash/size';
// import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
// import _slice from 'lodash/slice';
// import _sortBy from 'lodash/sortBy';
// import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
// import _toLower from 'lodash/toLower';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';
import FormSelect from '../../../components/FormSelect';

import { InfoButton, GreyButton, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix, getSelectOptions } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightEditorPanelHighlights' );

const Wrapper = styled.div`
    margin: 20px 0;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;

    p {
        margin-bottom: 15px;

        &:last-child { margin-bottom: 0; }
    }
`;


const InsightEditorPanelHighlights = ({
    insight,
    onUpdate
}) => {
    // keep those inside <mark> tags and remove the rest
    let mark_tags = ( insight && insight.contents_html && !_isEmpty( insight.contents_html ) && insight.contents_html.indexOf("<mark") >= 0 ? insight.contents_html.match( /<mark[^>]*>([\s\S]*?)<\/mark>/g ) : [] );

    const highlights = useMemo(() => {
        let _highlights = [],
            turndownService = new TurndownService();

        turndownService.addRule('highlights', {
            filter: ['mark'],
            replacement: function (content,node) {
                return node.getAttribute('data-color')+'__|C|__' + content;
            }
        });

        if ( mark_tags && isArrayExists( mark_tags ) ) {
            let count = 1;
            mark_tags.forEach(text => {
                let _text = turndownService.turndown( text ),
                    splitData = _text.split( '__|C|__' );
                _highlights.push({
                    id: count.toString(),
                    text: splitData[1],
                    color: splitData[0]
                });
                count++;
            });
        } // end - mark_tags

        return _highlights;
    }, [ mark_tags ] );

    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        { highlights && isArrayExists( highlights ) ? highlights.map(( highlight ) => {
            return <Typography variant="body1" key={highlight.id} style={{ backgroundColor: highlight.color }}>{highlight.text}</Typography>
        }) : <Typography variant="body2">No highlight added yet</Typography> }
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightEditorPanelHighlights);