/* eslint-disable */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';

import { PLATFORM_ORG_LOGO } from '../../constants';

export default class ScreenLoader extends React.Component {

    render() {
        const { text } = this.props;
        return (
        <FullScreenComponent style={{ background: '#212121', backgroundPosition: "center center", backgroundSize: "cover" }}>
            <FullScreenFlex justify="center" items="center">
                <div style={{ textAlign: 'center', padding: "60px 90px", borderRadius: "12px" }}>
                    <div style={{ marginBottom: "30px" }}><img src={PLATFORM_ORG_LOGO} style={{ maxWidth: '170px' }} /></div>
                    <CircularProgress size={80} thickness={6} color="primary" />
                    <div style={{ marginTop: "30px" }}>{ text || 'LOADING...' }</div>
                </div>
            </FullScreenFlex>
        </FullScreenComponent>
        )
    }

}