import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: indigo,
        background: '#333333'
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 16,
        h5: {
            fontSize: "1.5rem",
            fontWeight: "700",
            textTransform: 'uppercase'
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1.5rem",
                backgroundColor: '#212121',
            },
            arrow: {
                color: '#212121',
            }
        }
    }
});

export default theme;