/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import _forEach from 'lodash/forEach';
// import _size from 'lodash/size';
// import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
// import _slice from 'lodash/slice';
// import _sortBy from 'lodash/sortBy';
// import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
// import _toLower from 'lodash/toLower';

import Highlights from './editor_panel_highlights';
import Insights from './editor_panel_insights';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';
// import FormSelect from '../../../components/FormSelect';

import { InfoButton, GreyButton, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix, getSelectOptions } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightEditorPanel' );

const Wrapper = styled.div`
    padding: 15px 20px;
`;


const InsightEditorPanel = ({
    insight,
    folders,
    currentInsight,
    onInsightTrigger,
    onUpdate
}) => {
    const [ type, setType ] = useState( 'highlights' );

    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        
        <ButtonGroup disableElevation variant="contained" fullWidth={true}>
            <Button color={ type && type === 'highlights' ? 'primary' : null } onClick={() => setType('highlights')}>Highlights</Button>
            <Button color={ type && type === 'insights' ? 'primary' : null } onClick={() => setType('insights')}>Insights</Button>
        </ButtonGroup>

        { type && type === 'highlights' ? <Highlights 
            insight={insight} 
            onUpdate={onUpdate} /> : null }

        { type && type === 'insights' ? <Insights 
            insight={insight}
            onInsightTrigger={onInsightTrigger} 
            onUpdate={onUpdate} /> : null }

    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightEditorPanel);