/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Editor from './editor';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';
import FormSelect from '../../../components/FormSelect';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix, getSelectOptions } from '../../../helpers/data';
import { getMomentTime } from '../../../helpers/date';

const prefix = getClassPrefix( 'modules', 'InsightEditorTopBar' );

const Wrapper = styled.div`
    padding: 15px 20px;
`;

const TitleWrapper = styled.div`
    max-width: 950px;
    margin: 0 auto;
    padding-top: 10px;

    h4 {
        margin: 0;
    }
`;

const GoBackWrapper = styled.div`
    padding-top: 10px;
`;

const GoBackButton = styled(GreyButton)`
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    min-width: unset;
    padding: 0;
    border-radius: 100%;

    i {
        width: 100%;
        height: 100%;
    }
`;

const FolderSelector = styled.div`
    display: inline-block;
    max-width: 150px;
`;

const OptionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const InsightEditorTopBar = ({
    insight,
    folders,
    currentInsight,
    onUpdate,
    onGoBack
}) => {

    const handleGoBack = () => {
        if ( onGoBack )
            onGoBack();
    }

    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={1} align="left">
                <GoBackWrapper>
                    <Tooltip title="Go back" placement="right">
                        <GoBackButton onClick={handleGoBack}><i className="fa fa-long-arrow-left"></i></GoBackButton>
                    </Tooltip>
                </GoBackWrapper>
            </Grid>
            <Grid item xs={12} sm={8} align="left">
                <TitleWrapper>
                    <Editor 
                        content={"<h4>"+( currentInsight && currentInsight.title && !_isEmpty( currentInsight.title ) ? currentInsight.title : '' )+"</h4>"}
                        showBubbleMenu={false}
                        onUpdate={(newValue,editor) => onUpdate(editor.getText(),'title')}
                        />
                    <Typography variant="body2">Added On: {( insight.created_on ? getMomentTime( insight.created_on, 'MMMM Do YYYY, h:mm:ss a' ) : '-' )}</Typography>
                </TitleWrapper>
            </Grid>
            <Grid item xs={12} sm={3}>
                <OptionsWrapper>
                    { insight && insight.url && !_isEmpty( insight.url ) ? <AInfoLink href={insight.url} target="_blank" rel="noopener" style={{ margin: '0px 10px 0 0', padding: '10px 25px', borderRadius: '6px' }} ><i className="fa fa-external-link"></i>View Site</AInfoLink> : null }
                    <FolderSelector>
                        <FormSelect
                            label="Folder"
                            value={( insight && insight.category_id || '' )}
                            options={getSelectOptions({ list: ( folders || false ), options: [], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                            name="category_id"
                            onChange={onUpdate} />
                    </FolderSelector>
                </OptionsWrapper>
            </Grid>
        </Grid>
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightEditorTopBar);