/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from 'firebase/app';

import ScreenLoader from '../../components/ScreenLoader';

import { GreyButton } from '../../styles/button';


import { signInWithEmail, logout } from '../../actions/auth';
import { PLATFORM_NAME_LONG, PLATFORM_URL, DEV_MODE } from '../../constants';

class FBAuthVerifier extends React.Component {

    componentDidMount() {
        const { history } = this.props;

         // Confirm the link is a sign-in with email link.
        if ( firebase.auth().isSignInWithEmailLink(window.location.href) ) {
            this.doPasswordLessSignIn();
        } else {
            history.push('/login');
        }
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    doPasswordLessSignIn = () => {
        const { history } = this.props;
        this.props.dispatch(signInWithEmail(history));
    }

    handleLogout = event => {
        event.preventDefault();
        this.props.dispatch(logout());
    }

    render() {
        const { authData } = this.props;
        return authData ? (
        <div style={{ textAlign: 'center', padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)" }}>
            <div style={{ margin: "0 auto 30px auto", maxWidth: '175px' }}><img src="/logo.gif" style={{ display: "block", height: 'auto', width: '100%' }} /></div>
            <h1 style={{ fontWeight: "300", textTransform: "uppercase", marginBottom: "30px" }}>{PLATFORM_NAME_LONG}</h1>
            <GreyButton style={{ textTransform: 'uppercase', padding: "10px 45px" }} onClick={this.handleLogout}><i className="fa fa-power-off"></i>Logout</GreyButton>
        </div>
        ) : <ScreenLoader />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(FBAuthVerifier);