/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { getMomentNow } from '../../helpers/date';

import theme from '../../theme';

import { PLATFORM_COMPANY_NAME } from '../../constants';

const Wrapper = styled.div`
    border-top: 1px solid #ddd;
    padding: 20px 40px;
    background: #fff;
`;

const FooterLinks = styled.ul`
    display: block;
    text-align: right;

    li,
    a {
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        color: #212121;

        @media (max-width:1024px) {
            font-size: 11px;
        }
    }

    li {
        display: inline-block;
        &:after {
            display: inline-block;
            content: "|";
            padding: 0px 15px;
            color: #212121;
        }

        &:last-child {
            &:after { display: none; }
        }
    }

    a {
        color: #212121;
        &:hover {
            color: ${theme.palette.secondary.main};
        }
        &.active {
            color: #212121;
        }
    }

`;

class Footer extends React.Component {

    handleLogout = event => {
        const { onLogout } = this.props;
        event.preventDefault();
        if ( onLogout )
            onLogout();
    }

    render() {
        const { authData, adminView } = this.props;
        return (
        <Wrapper>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1" align="right">Copyright © 2012 - {getMomentNow('YYYY')} {PLATFORM_COMPANY_NAME}</Typography>
                    {/* <FooterLinks>
                        <li><a href="https://www.chumbaka.asia/privacy-policy" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://www.notion.so/Class-Policies-5a7aa95b628b432cb861ba3cba374d96" target="_blank">{"Class Policies"}</a></li>
                        <li><a href="https://www.notion.so/Parental-Consent-f2d1c75abcf5407f83e41ee58e7449b3" target="_blank">{"The Parental Consent"}</a></li>
                        { authData && !adminView ? <li><a href="#" onClick={this.handleLogout}>Logout</a></li> : null }
                    </FooterLinks> */}
                </Grid>
            </Grid>
        </Wrapper>
        )
    }

}

export default Footer