/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import _trim from 'lodash/trim';
import _size from 'lodash/size';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { AInfoLink, InfoButton, IndigoButton } from '../../styles/button';

import { toggleLoader } from '../../actions/global';

const getPromptFromDB = (tool) => {
    return new Promise((resolve,reject) => {
       
        let promises = [];

        switch( tool ) {
            case 'insert_keypoints':
                promises.push( firebase.firestore().collection("content_keypoints_summary").where('kp_reviewed','==','no').orderBy('id_num','asc').limit(1).get() );
                break;
            case 'insert_summary':
                promises.push( firebase.firestore().collection("content_keypoints_summary").where("status",'==','keypoints').orderBy('id_num','asc').limit(1).get() );
                break;
        }

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then( results => {
                let id = false,
                    prompt = '',
                    completion = '',
                    returnData = {};
                if ( results && isArrayExists( results ) ) {
                    switch( tool ) {
                        case 'insert_keypoints':
                            if ( results[0] ) {
                                results[0].forEach( doc => {
                                    const val = doc.data();
                                    id = doc.id;
                                    prompt = val.prompt;
                                    // chars = _size( prompt );
                                    // token = (chars/4).toFixed(0);

                                    returnData = { id, prompt, kp_completion: _trim( val.kp_completion ) };
                                });
                            }
                            break;
                        case 'insert_summary':
                            if ( results[0] ) {
                                results[0].forEach( doc => {
                                    const val = doc.data();
                                    id = doc.id;
                                    prompt = val.prompt + `\n\nKey Points:\n\n`+_trim( val.kp_completion );
                                    // chars = _size( prompt );
                                    // token = (chars/4).toFixed(0);

                                    returnData = { id, prompt };
                                });
                            }
                            break;
                    }
                }
                resolve(returnData);
            })
            .catch(error => {
                reject(error);
            })
        } else {
            resolve(false);
        }
        
    });
}

const GetPromptButton = ({
    tool,
    onUpdate = () => {}
}) => {
    const dispatch = useDispatch();

    const getPrompt = () => {
        dispatch(toggleLoader(true));
        getPromptFromDB(tool)
        .then( data => {
            dispatch(toggleLoader(false));
            if ( data )
                onUpdate(data);
            else
                triggerErrorAlert('No data found.');
        })
        .catch(error => {
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
    }

    return (
    <>
        <InfoButton size="small" onClick={getPrompt}>Get Prompt</InfoButton>
    </>
    );
}

export default GetPromptButton;