/* eslint-disable */
import Alert from 'react-s-alert';

import {
	MISC_CHANGES_NOT_SAVED,
	MISC_CHANGES_RESET,
	RESET_ARTICLE,
	RESET_ARTICLES,
	RESET_INSIGHT,
	RESET_INSIGHTS,
	RESET_NOTES,
	RESET_NOTES_LIST,
} from './types';

export const appChangesMade = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_NOT_SAVED });
	}
}

export const appChangesReset = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_RESET });
	}
}

export const resetRedux = (type) => {
	return dispatch => {
		switch( type ) {
			case 'article':
				setTimeout(() => {
					dispatch({ type: RESET_ARTICLE });
				}, 150);
				break;
			case 'articles':
				setTimeout(() => {
					dispatch({ type: RESET_ARTICLES });
				}, 150);
				break;
			case 'insight':
				setTimeout(() => {
					dispatch({ type: RESET_INSIGHT });
				}, 150);
				break;
			case 'insights':
				setTimeout(() => {
					dispatch({ type: RESET_INSIGHTS });
				}, 150);
				break;
			case 'notes':
				setTimeout(() => {
					dispatch({ type: RESET_NOTES });
				}, 150);
				break;
			case 'notes_list':
				setTimeout(() => {
					dispatch({ type: RESET_NOTES_LIST });
				}, 150);
				break;
		}
	}
}