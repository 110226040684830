/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';


import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { articleSchema } from '../../schemas/article';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */


export const fb_getArticles = (props) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("articles").get()
        .then(snapshot => {
            let list = [];
            if ( snapshot ) {
                snapshot.forEach(doc => {
                    let item = getDocDataBySchema( articleSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getArticlesOptions = (callback,props = {}) => {
    return new Promise((resolve,reject) => {
        const { project_id } = props;
        let Ref = firebase.firestore().collection("articles");

        // if ( region && !_isEmpty( region ) )
        //     Ref = Ref.where("course_visibility_ids","array-contains-any",[region]);

        Ref.onSnapshot(snapshot => {
            const list = [];
            if ( snapshot ) {
                snapshot.forEach( doc => {
                    let item = getDocDataBySchema( articleSchema, doc );
                    list.push( item );
                });
            } // end - snapshot
            callback(list);
        });

    })
}


export const fb_getArticle = (id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("articles").doc(id).get() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};
            if ( results && results[0] ) {
                item = getDocDataBySchema( articleSchema, results[0] );
            } // end - results[0]
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_addArticle = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'article', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateArticle = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'article', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteArticle = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'article', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}