/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import SnackBarSave from '../../components/SnackBarSave';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { FormBox } from '../../styles/form';
import { InfoButton } from '../../styles/button';

import { isAdmin } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getUserProfile, updateUserProfile } from '../../actions/auth';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { userSchema } from '../../schemas/user';

class AuthProfilePage extends React.Component {

    state = {
        user_profile: false,
        randNum: false
    };

    componentDidMount() {
        const { authData, 
            // designationList, currencyList, regionsList, rolesList 
        } = this.props;
        // retrieve user profile
        if ( authData && authData.uid )
            this.props.dispatch(getUserProfile(authData.uid));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { userProfile, randNum, 
            // designationList, currencyList, regionsList, rolesList
            } = this.props;

        // for course
        if ( userProfile && randNum && randNum !== this.state.randNum ) {
            this.setState({ user_profile: cloneCollections( userProfile ), randNum });
        } // end - currentCourse
    }

    handleSaveChanges = (event) => {
        const { authData } = this.props;
        const { user_profile } = this.state;
        event.preventDefault();
        var error = false,
            formData = {
                uid: ( user_profile && user_profile.uid && !_isEmpty(user_profile.uid) ? user_profile.uid : '' ),
                name: ( user_profile && user_profile.name && !_isEmpty(user_profile.name) ? user_profile.name : '' )
            };

        // do error check
        if ( !isAdmin( authData ) ) {
            userSchema.forEach(schema => {
                formData[schema.id] = ( user_profile && user_profile[schema.id] ? cloneCollections( user_profile[schema.id] ) : schema.default );
                if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                    error = 'Please fill out "' + schema.label + '" field';
                }
            });
        } // end - authData

        if ( !( formData && formData.name && !_isEmpty(formData.name) ) )
            error = 'Please fill out "Name" field';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateUserProfile(formData));
        } // end - error

    }

    handleFormUpdate = ( newValue, key ) => {
        const { user_profile } = this.state;
        let newData = ( user_profile && isObjectExists( user_profile ) ? cloneCollections( user_profile ) : {} );
        newData[key] = newValue;
        this.setState({ user_profile: newData });
        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderSimpleForm = () => {
        const { regionsList, rolesList } = this.props;
        const { user_profile } = this.state;
        return (
        <FormBox>
            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                    <FormSelect 
                        label="Licensee" 
                        name="region" 
                        value={( user_profile.region || '' )} 
                        disabled={true}
                        options={getSelectOptions({ list: regionsList, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                        onChange={this.handleFormUpdate} />
                </Grid> */}
                <Grid item xs={6}>
                    <FormInput label="Name (Required)" name="name" value={( user_profile.name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Email Address *" name="email" type="email" value={( user_profile.email || '' )} disabled={true} onChange={this.handleFormUpdate} />
                </Grid>
                {/* <Grid item xs={6}>
                    <div style={{ paddingTop: '10px' }}><FormSelect 
                    label="Role" 
                    name="role" 
                    value={( user_profile.role || '' )} 
                    disabled={true}
                    options={getSelectOptions({ list: rolesList, options: [{ value: '', label: 'Select an Option' },{ value: 'admin', label: 'Super Admin' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                    onChange={this.handleFormUpdate} /></div>
                </Grid> */}
            </Grid>
        </FormBox>
        );
    }

    renderContents() {
        const { authData, userProfile, designationList, currencyList, regionsList, rolesList, changesMade } = this.props;
        const { user_profile } = this.state;
        return (
        <div>
            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderSimpleForm()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />
        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Edit Profile"
                subtitle="Account Settings"
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        userProfile: state.auth && state.auth.profile ? state.auth.profile : null,
        randNum: state.auth && state.auth.user ? state.auth.rand : null,
        // designationList: state.maintenance && state.maintenance.designations || null,
        // currencyList: state.maintenance && state.maintenance.currency || null,
        // regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        // rolesList: state.maintenance && state.maintenance.roles ? state.maintenance.roles : false,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(AuthProfilePage);