/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';
import { getAPIErrorMessage } from '../../helpers/action';

import { triggerTrainAction } from '../../actions/train';
// import { getMaintenance } from '../../actions/maintenance';
import { toggleLoader } from '../../actions/global';
import { appChangesMade, appChangesReset, resetRedux } from '../../actions/misc';


class TrainerPage extends React.Component {

    state = {
        tool: 'paraphraser',
        log: {
            id: '',
            prompt: '',
            completion: ''
        },
        docData: {},
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
    }

    handleSaveChanges = (event) => {
        const { log, tool } = this.state;
        event.preventDefault();
        var error = false,
            action = '';

        switch( tool ) {
            case 'paraphraser':
                action = 'paraphrase';
                break;
            default:
                action = 'save_' + tool;
                break;
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(toggleLoader(true));
            triggerTrainAction({
                id: ( log && log.id || '' ),
                prompt: ( log && log.prompt || '' ),
                completion: ( log && log.completion || '' )
            },action)
            .then(data => {
                this.props.dispatch(toggleLoader(false));
                this.props.dispatch(appChangesReset());
                // reset
                switch(tool) {
                    case 'insert_keypoints':
                        this.setState({ log: { 
                            id: '',
                            prompt: `Title: 

Content:

`, completion: '' }, docData: {} });
                        break;
                    case 'insert_summary':
                    case 'paraphraser':
                        this.setState({ log: { id: '', prompt: '', completion: '' }, docData: {} });
                        break;
                }
            })
            .catch(error => {
                triggerErrorAlert(getAPIErrorMessage(error));
                this.props.dispatch(toggleLoader(false));
            });
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ log: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderForm = () => {
        const { authData, changesMade } = this.props;
        const { log, tool, docData } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            log: ( log || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        return <EditForm 
                formType="add"
                tool={tool}
                docData={docData}
                onToolChange={(newValue) => this.setState({ tool: newValue })}
                onDocDataUpdate={(newValue) => this.setState({ docData: newValue })}
                {...tabProps} />
    }

    renderContents() {
        return (
        <div>

            {this.renderForm()}
            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Trainer"
                // subtitle="Articles"
                maxWidth="1110px"
                contentWrapperStyle={{ padding: '0px' }}
                onLoad={false}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(TrainerPage);