/* eslint-disable */
export const SAMPLE_ACTION = 'sample_action';

/* auth */
export const IS_LOGGED_IN = 'is_logged_in';
export const AUTH_LOGIN = 'auth_login';
export const AUTH_LOGOUT = 'auth_logout';
export const AUTH_GET_PROFILE = 'auth_get_profile';
export const AUTH_EDIT_PROFILE = 'auth_edit_profile';

/* users actions */
export const GET_USERS = 'get_users';
export const GET_USERS_OPTIONS = 'get_users_options';
export const ADD_USER = 'add_user';
export const EDIT_USER = 'edit_user';
export const DELETE_USER = 'delete_user';

/* articles actions */
export const GET_ARTICLES = 'get_articles';
export const GET_ARTICLES_OPTIONS = 'get_articles_options';
export const GET_ARTICLE = 'get_article';
export const ADD_ARTICLE = 'add_article';
export const EDIT_ARTICLE = 'edit_article';
export const DELETE_ARTICLE = 'delete_article';
export const RESET_ARTICLE = 'reset_article';
export const RESET_ARTICLES = 'reset_articles';

/* insights actions */
export const GET_INSIGHTS = 'get_insights';
export const GET_INSIGHTS_OPTIONS = 'get_insights_options';
export const GET_INSIGHTS_CATEGORIES = 'get_insights_categories';
export const GET_INSIGHT = 'get_insight';
export const ADD_INSIGHT = 'add_insight';
export const EDIT_INSIGHT = 'edit_insight';
export const DELETE_INSIGHT = 'delete_insight';
export const RESET_INSIGHT = 'reset_insight';
export const RESET_INSIGHTS = 'reset_insights';

/* notes actions */
export const GET_NOTES_LIST = 'get_notes_list';
export const GET_NOTES_OPTIONS = 'get_notes_options';
export const GET_NOTES = 'get_notes';
export const ADD_NOTES = 'add_notes';
export const EDIT_NOTES = 'edit_notes';
export const DELETE_NOTES = 'delete_notes';
export const RESET_NOTES = 'reset_notes';
export const RESET_NOTES_LIST = 'reset_notes_list';

/* media library */
export const GET_MEDIA_LIBRARY = 'get_media_library';
export const UPLOAD_NEW_MEDIA = 'upload_new_media';
export const DELETE_MEDIA = 'delete_media';

/* maintenance actions */
// export const M_GET_REGIONS = 'm_get_regions';
// export const M_REGIONS_UPDATED = 'm_regions_updated';
// export const M_GET_ROLES = 'm_get_roles';
// export const M_ROLES_UPDATED = 'm_roles_updated';

// export const M_GET_CERTIFICATES = 'm_get_certificates';
// export const M_GET_CERTIFICATE = 'm_get_certificate';
// export const M_CERTIFICATE_UPDATED = 'm_certificate_updated';
// export const MAINTENANCE_UPDATED = 'maintenance_updated';

/* global actions */
export const GLOBAL_MODAL_PROCESSING = 'global_modal_processing';
export const GLOBAL_MODAL_DELETING = 'global_modal_deleting';
export const GLOBAL_ERROR_TRIGGER = 'global_error_trigger';
export const GLOBAL_LOADER_START = 'global_loader_start';
export const GLOBAL_LOADER_END = 'global_loader_end';
export const GLOBAL_SNACKBAR_TRIGGER = 'global_snackbar_trigger';

/* MISC actions */
export const MISC_CHANGES_SAVED = 'misc_changes_saved';
export const MISC_CHANGES_RESET = 'misc_changes_reset';
export const MISC_CHANGES_NOT_SAVED = 'misc_changes_not_saved';