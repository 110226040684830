/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {useKeyPress} from 'react-use';
import styled from "styled-components";
import Alert from "react-s-alert";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _trim from 'lodash/trim';

import CodeEditor from './code_editor';
import SemanticSearchResults from './semantic_search_results';

import ModalView from '../../components/ModalView';
import MarkdownEditor from '../../components/MarkdownEditor';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormEditor from '../../components/FormEditor';
import FormUpload from '../../components/FormUpload';
import ToolTipInfo from '../../components/ToolTipInfo';

import { FormBox } from '../../styles/form';
import { InfoBox } from '../../styles/message';
import { AInfoLink, InfoButton, IndigoButton } from '../../styles/button';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { articleSchema } from '../../schemas/article';

import { triggerWriteAction } from '../../actions/write';
import { toggleLoader } from '../../actions/global';

import { YES_NO_OPTIONS } from '../../constants';

const ContentTextareaWrapper = styled.div`
    .MuiOutlinedInput-multiline {
        padding: 5px;
        align-items: flex-start;
        font-size: 16px;
    }
    .MuiOutlinedInput-inputMultiline {
        padding: 30px;
        line-height:1.5;
    }

`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        // borderBottom: "1px solid #ddd"
    }
});

const KeyPressEvents = (props) => {
    const { onKeyPressed } = props;
    // add command+enter keypress event using useKeyPress
    useKeyPress((e) => {
        if ( onKeyPressed )
            onKeyPressed(e);
    });
    
    return (
    <></>
    )
}

class EditForm extends React.Component {

    state = {
        length: 'short',
        max_token: 650,
        temperature: 0.75,
        top_p: 1,
        writing: false,
        openResultsModal: false,
        results: false
    };

    handleTypeEffects = (character) => {
        const { article } = this.props;
        let newContent = ( article && article.contents ? article.contents : '' ) + character;
        this.handleFormUpdate( newContent, 'contents' );
    }

    doTypingEffects = (newContent) => {
        for (let index = 0; index < _size(newContent); index++) {
            setTimeout(() => {
                this.handleTypeEffects(newContent.substring(index,index+1));
            }, 150);
        }
    }

    handleWriteAction = () => {
        const { article } = this.props;
        const { length, max_token, top_p } = this.state;
        let error = false,
            context = '',
            splitData = _split( article.contents, '///' );

        // get context
        if ( splitData && isArrayExists( splitData ) ) {
            let size = _size( splitData ),
                index = size - 1;
            context = ( splitData[index] && !_isEmpty( splitData[index] ) ? _trim( splitData[index] ) : '' );
        }
        
        // do error check
        
        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.setState({ writing: true });
            // tester
//             setTimeout(() => {
//                 this.setState({ writing: false });
//                 this.doTypingEffects(`
// return (

// <div className="header">

// <h1 className="header__title">{title}</h1>

// <h2 className="header__description">{description}</h2>")
//                 `);
//             }, 1500);
            // real trigger
            triggerWriteAction({
                context,
                length,
                max_token,
                top_p,
                model: this.getAIModel( article ),
                query: ( article && article.query ? article.query : '' )
            },( article && article.action ? article.action : '' ))
            .then(data => {
                this.setState({ writing: false });
                switch( article.action ) {
                    case 'semantic_search':
                        this.setState({ openResultsModal: true, results: data });
                        break;
                    default:
                        this.doTypingEffects(( data && data.choices && isArrayExists( data.choices ) && data.choices[0] && data.choices[0].text && !_isEmpty( data.choices[0].text ) ? data.choices[0].text : '' ));
                        break;
                }
            })
            .catch(error => {
                triggerErrorAlert(getAPIErrorMessage(error));
                this.setState({ writing: false });
            });
        } // end - error

    }

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, article, currentCourse, course_type, course_duration, currency } = this.props;
        let newData = ( article && !_isEmpty( article ) ? cloneCollections( article ) : {} );

        switch( key ) {
            // case 'contents':
            //     newData[key] = turndownService.turndown(( newValue || '' ));
            //     break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getAIModel = (article) => {
        let model = ''
        if ( article && article.action ) {
            switch( article.action ) {
                case 'sentence_to_story_seth':
                    model = 'curie:ft-smashing-advantage-2021-08-06-01-29-27';
                    break;
                case 'sentence_to_story_ben':
                    model = 'curie:ft-smashing-advantage-2021-12-06-21-34-04';
                    break;
            }
        } 
        return model;
    }

    getActionBtnLabel = () => {
        const { article } = this.props;
        if ( article && article.action ) {
            switch( article.action ) {
                case 'write_for_me':
                case 'write_coding_tutorial':
                    return 'Write Now';
                case 'write_like_seth':
                case 'write_like_ben':
                case 'sentence_to_story_seth':
                case 'sentence_to_story_ben':
                case 'passage_to_content_ben':
                // case 'sentence_to_story_goins':
                // case 'sentence_to_story_zenhabits':
                    return 'Write Now';
                case 'code_for_me':
                    return 'Code Now';
                default:
                    return 'Perform';
            }
        } else {
            return 'Perform';
        }
    }

    getFieldOptions = (field) => {
        const { article } = this.props;

        switch( field.name ) {
            // case 'course_type':
            //     return getSelectOptions({ list: ( course_type || false ), options: [], keys: { value: 'id', label: 'label', disabled: 'status' }, sortBy: 'label' });
            case 'publish':
                return YES_NO_OPTIONS;
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { article } = this.props;
        switch( field.name ) {
            default:
                return ( article && article[field.name] ? article[field.name] : ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getFieldHeight = (field) => {
        switch( field.name ) {
            case 'price_per_pax':
                return 300;
            default:
                return 500;
        }
    }

    getTooltip = (schema) => {
        switch( schema.id ) {
            // case 'lms_course_link':
            //     return <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-enable-article-self-enrollment-with-a-join-code-or/ta-p/830" target="_blank">Refer to this method</a>;
            default:
                return;
        }
    }

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find( articleSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') && !massEditMode ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( massEditMode ? null : ( schema.default || null ) ),
            disabled: ( schema.id === 'publish' ? true : ( schema.disabled ? this.isFieldDisabled( schema ) : false ) ),
            tooltip: this.getTooltip(schema)
        } : null );
    }

    renderField = (id) => {
        const { authData, article, dispatch } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 12 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} disableNative={true} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'tinymce_editor':
                    return <FormEditor id={id + '-' + ( article && article.id && !_isEmpty( article.id ) ? article.id : '' )} {...field} value={this.getFieldValue(field)} height={this.getFieldHeight(field)} onChange={this.handleFormUpdate} filePickerTypes= 'image' filePickerCallback={filePickerCallBack(authData,article,field,dispatch)} />;
            }
        } // end - field.field_type
    }

    renderCreatedModifiedDate = (item) => {
        let created_on = ( item.created_on && item.created_on._seconds ? item.created_on._seconds*1000 : ( item.created_on || null ) ),
            modified_on = ( item.modified_on && item.modified_on._seconds ? item.modified_on._seconds*1000 : ( item.modified_on || null ) );
        return (
        <div style={{ paddingTop: "15px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
            <div>{ created_on ? 'Created on ' + getMomentTime( created_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            <div>{ modified_on ? 'Last Modified on ' + getMomentTime( modified_on , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
        </div>
        )
    }

    renderPlaygroundForm = () => {
        const { classes, article } = this.props;
        const { length, writing, max_token, top_p, temperature } = this.state;

        return (
        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", minHeight: '100vh' }}>
            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>Playground</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormSelect 
                            label="Action"
                            name="action"
                            value={( article && article.action ? article.action : '' )} 
                            options={[
                                { value: 'write_for_me', label: 'Write For Me' },
                                { value: 'write_coding_tutorial', label: 'Write Coding Tutorial' },
                                { value: 'write_like_seth', label: 'Write Like Seth' },
                                { value: 'write_like_ben', label: 'Write Like Ben' },
                                { value: 'code_for_me', label: 'Code For Me' },
                                { value: 'semantic_search', label: 'Semantic Search' },
                                { value: 'sentence_to_story_seth', label: 'Sentence To Story (Seth)' },
                                { value: 'sentence_to_story_ben', label: 'Sentence To Story (Ben)' },
                                { value: 'passage_to_content_ben', label: 'Passage to Content (Ben)' },
                            ]} 
                            disableNative={true} 
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    { article && article.action && (
                        article.action === 'write_for_me' || 
                        article.action === 'write_coding_tutorial' || 
                        article.action === 'write_like_seth' || 
                        article.action === 'write_like_ben'
                    ) ? (
                    <>
                        <Grid item xs={12} style={{ padding: '15px' }}>
                            <FormRadio 
                                label="Length"
                                value={( length || 'short' )} 
                                options={[
                                    { value: 'short', label: 'Short' },
                                    { value: 'medium', label: 'Medium' },
                                    { value: 'long', label: 'Long' },
                                    { value: 'custom', label: 'Custom' },
                                ]} 
                                onChange={(newValue) => this.setState({ length: newValue })} />
                        </Grid>
                        { length && length === 'custom' ? (
                        <Grid item xs={12} style={{ padding: '15px' }}>
                            <FormInput 
                                label="Max Token"
                                type="number" 
                                value={( max_token || 0 )} 
                                onChange={(newValue) => this.setState({ max_token: newValue })} />
                        </Grid>
                        ) : null }
                    </>
                    ) : null }
                    { article && article.action && (
                        article.action === 'sentence_to_story_seth' ||
                        article.action === 'sentence_to_story_ben' || 
                        article.action === 'passage_to_content_ben'
                    ) ? (
                    <>
                        <Grid item xs={12} style={{ paddingTop: '10px' }}>
                            <FormInput
                                name="query"
                                label="Sentence"
                                rows={9} 
                                multiline={true} 
                                value={( article && article.query ? article.query : '' )} 
                                onChange={this.handleFormUpdate} />
                        </Grid>
                    </>
                    ) : null }
                    { article && article.action && (
                        article.action === 'sentence_to_story_seth' ||
                        article.action === 'sentence_to_story_ben'
                    ) ? (
                    <>
                        <Grid item xs={12} style={{ padding: '15px' }}>
                            <FormInput 
                                label="Max Token"
                                type="number" 
                                value={( max_token || 0 )} 
                                onChange={(newValue) => this.setState({ max_token: newValue })} />
                        </Grid>
                    </>
                    ) : null }
                    { article && article.action && (
                        article.action === 'write_coding_tutorial' || 
                        article.action === 'write_like_seth' || 
                        article.action === 'write_like_ben' 
                    ) ? (
                    <>
                        <Grid item xs={12} style={{ padding: '15px' }}>
                            <FormInput 
                                label="Temperature"
                                value={( temperature || '0' )} 
                                onChange={(newValue) => this.setState({ temperature: newValue })} />
                        </Grid>
                        <Grid item xs={12} style={{ padding: '15px' }}>
                            <FormInput 
                                label="Top P"
                                value={( top_p || '0' )} 
                                onChange={(newValue) => this.setState({ top_p: newValue })} />
                        </Grid>
                    </>
                    ) : null }
                    { article && article.action && (
                        article.action === 'semantic_search'
                    ) ? (
                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <FormInput
                            name="query"
                            label="Query"
                            rows={3} 
                            multiline={true} 
                            value={( article && article.query ? article.query : '' )} 
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    ) : null }
                    <Grid item xs={12} style={{ paddingTop: '30px' }}>
                        <IndigoButton 
                            disabled={( writing ? 'yes' : null )}
                            onClick={this.handleWriteAction} 
                            style={{ width: '100%', textAlign: 'center', padding: '10px', borderRadius: '6px' }} 
                            rightIcon={writing ? null :"yes"}>
                                { writing ? null : this.getActionBtnLabel() }
                                <i className={"fa "+( writing ? "fa-spinner fa-pulse" : "fa-long-arrow-right" )}></i>
                            </IndigoButton>
                    </Grid>
                </Grid>
            </FormBox>
        </Paper>
        )
    }

    renderContentForm = () => {
        const { classes, article } = this.props;
        const { writing } = this.state;
        return (
        <Paper elevation={3} style={{ padding: "20px 30px", background: "#fff", minHeight: '100vh' }}>
            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>Content</Typography>
                {this.renderField('title')}
                <div style={{ paddingTop: '20px' }}>
                    { article && article.action && (
                        article.action === 'code_for_me' || 
                        article.action === 'write_coding_tutorial'
                    ) ? (
                    <>
                        { article && article.action && article.action === 'code_for_me' ? <CodeEditor
                            id="main_content_area" 
                            name="contents"
                            label="Contents"
                            language="javascript"
                            content={( article && article.contents ? article.contents : '' )} 
                            onChange={this.handleFormUpdate} /> : null }
                        { article && article.action && article.action === 'write_coding_tutorial' ? (
                        <MarkdownEditor
                            height={800}
                            tabSize={4}
                            name="contents"
                            value={( article && article.contents ? article.contents : '' )} 
                            onChange={this.handleFormUpdate} />
                        ) : null }
                    </>
                    ) : (
                    <ContentTextareaWrapper>
                        <FormInput
                            id="main_content_area"
                            name="contents"
                            label="Contents"
                            rows={30} 
                            multiline={true} 
                            value={( article && article.contents ? article.contents : '' )} 
                            onChange={this.handleFormUpdate} />
                    </ContentTextareaWrapper>
                    )}
                </div>  
            </FormBox>
        </Paper>
        )
    }

    render = () => {
        const { formType, classes, article, disableSystemDate } = this.props;
        const { openResultsModal, results } = this.state;

        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    {this.renderContentForm()}
                </Grid>
                <Grid item xs={4}>
                    {this.renderPlaygroundForm()}
                </Grid>
            </Grid>

            <KeyPressEvents 
                onKeyPressed={(e) => {
                    // add keypress event for command+enter or control+enter
                    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                        this.handleWriteAction();
                    }
                }} />

            <SemanticSearchResults
                open={openResultsModal}
                data={results}
                onClose={() => this.setState({ openResultsModal: false, results: false })} />

            { disableSystemDate ? null : this.renderCreatedModifiedDate(article)}
        </>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(EditForm);