/* eslint-disable */
import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

// import styles 
import theme from './theme';
import { OpacityComponent } from './styles/division';

// import components
import ScreenLoader from './components/ScreenLoader';
import OpacityLoader from './components/OpacityLoader';
import RouterModifier from './components/RouterModifier';

// import pages
import Dashboard from './pages/Dashboard';

// import UsersPage from './pages/UsersPage';
import LoginPage from './pages/Login';
import AuthVerifier from './pages/AuthVerifier';
import AuthProfilePage from './pages/AuthProfilePage';

import ArticlePage from './pages/ArticlePage';
import ArticlesPage from './pages/ArticlesPage';
import Insight from './modules/Insights/insight';
import Insights from './modules/Insights/insights';
import NotesPage from './pages/NotesPage';
import NotesListPage from './pages/NotesListPage';
import TrainerPage from './pages/TrainerPage';
import FR6PostsPage from './pages/FR6PostsPage';

// helpers
import { hasAccessRights, isAdminType, isUserType } from './helpers/auth';

// import actions
import { isUserLoggedIn } from './actions/auth';

const isActive = (authData) => {
    // for admin & super admin
    if ( authData && isAdminType( authData ) ) {
        return ( authData && authData.status && authData.status === 'active' ? true : false );
    } else if ( authData && isUserType(authData) ) {
        // for student or mentor ignore status
        return true;
    } // end - authData
}

const isSuperAdmin = (authData) => {
    return ( authData && authData.type && authData.type === 'superadmin' ? true : false );
}

/* Authentication based routes */
const PublicRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => {
            if ( isAdminType(authData) && isActive(authData) ) {
                // if is admin, redirect to admin dashboard
                return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            } else if ( isUserType(authData) && isActive(authData) ) {
                // if is user, redirect to student dashboard
                // return <Redirect to={{ pathname: '/student/dashboard', state: { from: props.location } }} />
                // above codes not ready yet
                // temp return the current element first
                return React.createElement(component,props);
            } else {
                // else, return the current element
                return React.createElement(component,props);
            } // end - authData
        }} />
    )
}

const UserRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route {...rest} render={ props =>
            authData ? ( ( isAdminType(authData) || isUserType(authData) ) && isActive(authData) ? React.createElement(component,props) : (
                <Redirect to={{ pathname: '/noaccess', state: { from: props.location } }} />
            ) ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        } />
    )
}

const AdminRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route {...rest} render={ props =>
            authData ? ( isAdminType(authData) && isActive(authData) ? (
                ( 
                    hasAccessRights(authData,accessRights) ? React.createElement(component,props) : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                )
            ) : (
                <Redirect to={{ pathname: '/noaccess', state: { from: props.location } }} />
            ) ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        } />
    )
}

const SuperAdminRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props =>  
            isSuperAdmin(authData) && isActive(authData) ? (
                React.createElement(component,props)
            ) : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
        } />
    )
}

// open route (ignore authData)
const OpenRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => React.createElement(component,props) } />
    )
}

const TestRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData && authData.email && authData.email == 'nathan@smashingadvantage.com' ? (
                React.createElement(component,props)
            ) : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
        } />
    )
}

/* App Structure */
class Structure extends React.Component {
    state = {
        authRand: false,
        dataLoaded: false
    }

    componentDidMount() {
        // call for auth listener
        this.props.dispatch(isUserLoggedIn());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authRand } = this.props;
        if ( authRand && !this.state.authRand ) {
            this.setState({ dataLoaded: true, authRand });
        }
    }
    
    renderStructure() {
        const { loader, authData, loader_label } = this.props;
        return (
        <React.Fragment>
            { loader ? <OpacityLoader open={loader} message={( loader_label || '' )} /> : null }
            <BrowserRouter>
                <RouterModifier>
                <Switch>
                    <AdminRoute path="/dashboard" component={Dashboard} authData={authData} />

                    <AdminRoute path="/articles/:article_id" component={ArticlePage} authData={authData} accessRights={['atr','atw']} />
                    <AdminRoute path="/articles" component={ArticlesPage} authData={authData} accessRights={['atr','atw']} />

                    <AdminRoute path="/insights/:insight_id" component={Insight} authData={authData} accessRights={['isr','isw']} />
                    <AdminRoute path="/insights" component={Insights} authData={authData} accessRights={['isr','isw']} />

                    <AdminRoute path="/notes/:notes_id" component={NotesPage} authData={authData} accessRights={['ntr','ntw']} />
                    <AdminRoute path="/notes" component={NotesListPage} authData={authData} accessRights={['ntr','ntw']} />

                    <AdminRoute path="/train" component={TrainerPage} authData={authData} accessRights={['ttr','ttw']} />

                    <AdminRoute path="/fr6" component={FR6PostsPage} authData={authData} accessRights={['frr','frw']} />

                    <AdminRoute path="/auth/profile" component={AuthProfilePage} authData={authData} />
                    <AdminRoute path="/auth" component={AuthProfilePage} authData={authData} />

                    {/* <SuperAdminRoute path="/users" component={UsersPage} authData={authData} />
                    <SuperAdminRoute path="/roles" component={MaintenanceRoles} authData={authData} /> */}

                    {/* <OpenRoute path="/b" component={BadgeDetailsPage} authData={authData} /> */}

                    <PublicRoute path="/fbauth" component={AuthVerifier} authData={authData} />
                    <PublicRoute path="/login" component={LoginPage} authData={authData} />
                    <PublicRoute path="/noaccess" component={LoginPage} authData={authData} />
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                </Switch>
                </RouterModifier>
            </BrowserRouter>
        </React.Fragment>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        return (
        <ThemeProvider theme={theme}>
            {(dataLoaded ? this.renderStructure() : <ScreenLoader /> )}
        </ThemeProvider> 
        );
    }
}

const mapStateToProps = state => {
    return {
        authData: state.auth.user,
        authRand: state.auth.rand,
        loader: state.global.loader,
        loader_label: state.global.loader_label
    };
};

export default connect(mapStateToProps)(Structure);