/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import _forEach from 'lodash/forEach';
// import _size from 'lodash/size';
// import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
// import _slice from 'lodash/slice';
// import _sortBy from 'lodash/sortBy';
// import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _map from 'lodash/map';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';
// import FormSelect from '../../../components/FormSelect';

import { InfoButton, GreyButton, AInfoLink, InverseButton, ErrorButton, IndigoButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists, isObjectExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix, getSelectOptions } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightEditorPanelInsights' );

const Wrapper = styled.div`

`;

const ContentWrapper = styled.div`
    margin: 20px 0;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;

    h4 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 15px;

        &:last-child { margin-bottom: 0; }
    }
`;

const InsighTriggerWrapper = styled.div`
    margin: 20px 0;
    text-align: center;
`;

const InsightTriggerButton = styled(IndigoButton)`
    min-width: 0px;
    padding: 10px 25px;
    border-radius: 25px;
`;


const InsightEditorPanelInsights = ({
    insight,
    onInsightTrigger,
    onUpdate
}) => {

    const handleInsightTrigger = () => {
        if ( onInsightTrigger )
        onInsightTrigger();
    }
    
    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <InsighTriggerWrapper>
            <InsightTriggerButton 
                onClick={handleInsightTrigger}>
                <i className={"fa fa-"+( insight && insight.insights && isObjectExists( insight.insights ) ? 'retweet' : 'cog' )}></i>
                {( insight && insight.insights && isObjectExists( insight.insights ) ? 'Re-generate Insight' : 'Generate Insight' )}
            </InsightTriggerButton>
        </InsighTriggerWrapper>
        <ContentWrapper className={prefix+"ContentWrapper"}>
            { insight && insight.insights && isObjectExists( insight.insights ) ? _map(insight.insights,( insight ) => {
                let section = ( insight.contents && isArrayExists( insight.contents ) ? _find( insight.contents, { id: insight.id } ) : false );
                return (
                <>
                    { section && section.heading && !_isEmpty( section.heading ) ? <Typography variant="h4" key={insight.id}>{section.heading}</Typography> : null }
                    <Typography variant="body1" key={insight.id}>{insight.contents}</Typography>
                </>
                )
            }) : <Typography variant="body2">No insight(s) generated yet</Typography> }
        </ContentWrapper>
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightEditorPanelInsights);