import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

import './reset.css';
import './index.css';
import App from './app';
// import reportWebVitals from './reportWebVitals';

import { FIREBASE_CONFIG_DATA } from './firebase';

// init fb
firebase.initializeApp(FIREBASE_CONFIG_DATA);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
