/* eslint-disable */
import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"

const CodeEditor = (props) => {
    const { id, onChange, name, content } = props;

    const handleUpdate = (value) => {
        if ( onChange )
            onChange(value,name);
    };

    return (
    <>
        <AceEditor
            mode="javascript"
            theme="monokai"
            value={content}
            onChange={handleUpdate}
            name={id}
            highlightActiveLine={true}
            fontSize={16}
            width="100%"
            height="550px"
            wrapEnabled={true}
            editorProps={{ 
                $blockScrolling: true 
            }}
        />
    </>
    );
};

export default CodeEditor;