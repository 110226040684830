/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import amber from '@material-ui/core/colors/amber';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';
import _round from 'lodash/round';

import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { stringToCurrency } from '../../helpers/number';

import { InverseButton, GreyButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        // borderBottom: "1px solid #ddd"
    }
});

class SemanticSearchResults extends React.Component {

    getHeatColor = (item) => {
        let color = 'none';
        const category = this.getHeatCategory(item);
        switch (category) {
            case 'top-10': color = amber['300']; break;
            case 'top-25': color = amber['100']; break;
            case 'above-average': color = amber['50']; break;
        }
        return color;
    }

    getHeatCategory = (item) => {
        let category = 'all';
        const average = this.getAverageScore();
        const top25 = this.getTop25Score();
        const top10 = this.getTop10Score();
        if ( item.score >= top10 ) {
            category = 'top-10';
        } else if ( item.score >= top25 ) {
            category = 'top-25';
        } else if ( item.score > average ) {
            category = 'above-average';
        }
        return category;
    }

    getTop25Score = () => {
        const highest = this.getHighestScore();
        return highest*75/100;
    }

    getTop10Score = () => {
        const highest = this.getHighestScore();
        return highest*90/100;
    }

    getHighestScore = () => {
        const { notes } = this.props;
        let highest = 0;
        if ( notes && notes.results && isArrayExists( notes.results ) ) {
            _forEach( notes.results, item => {
                let score = ( item.score && !_isEmpty( item.score ) ? stringToCurrency( item.score ) : 0 );
                if ( score && score > 0 && score > highest ) {
                    highest = score;
                } // end - score
            });
        } // end - notes
        return highest;
    }

    getAverageScore = () => {
        const { notes } = this.props;
        let average = 0,
            average_score = 0,
            average_count = 0;
        if ( notes && notes.results && isArrayExists( notes.results ) ) {
            _forEach( notes.results, item => {
                let score = ( item.score && !_isEmpty( item.score ) ? stringToCurrency( item.score ) : 0 );
                    // average_count++;
                    // average_score += score;
                if ( score && score > 0 ) {
                    average_count++;
                    average_score += score;
                } // end - score
            });
        } // end - notes
        return ( average_score && average_count ? _round( average_score/average_count ) : 0 );
    }

    renderNote = (item) => {
        const { notes, display_option, show_score, show_color = 'yes' } = this.props;
        const average = this.getAverageScore();
        const category = this.getHeatCategory(item);
        let show = false;
        if ( display_option && display_option === 'top-10' && category === 'top-10' ) {
            show = true;
        } else if ( display_option && display_option === 'top-25' && ( category === 'top-25' || category === 'top-10' ) ) {
            show = true;
        }  else if ( display_option && display_option === 'above-average' && ( category === 'above-average' || category === 'top-25' || category === 'top-10' ) ) {
            show = true;
        } else if ( display_option && display_option === 'all' ) {
            show = true;
        }
        return ( show ? (
        <Grid key={item.id} container spacing={2}>
            <Grid item xs={ show_score && show_score === 'yes' ? 10 : 12 }>
                <Typography variant="body1" style={ show_color && show_color === 'yes' ? { background: this.getHeatColor(item) } : null }>{( item.string || '' )}</Typography>
            </Grid>
            { show_score && show_score === 'yes' ? (
            <Grid item xs={2}>
                <Typography variant="body1" align="right">{( item.score || '' )}</Typography>
            </Grid>
            ) : null }
        </Grid>
        ) : null )
    }

    render() {
        const { notes, classes, showAverageScore, showTitle } = this.props;
        const average = this.getAverageScore();
        return (
        <>
            { showTitle && showTitle === 'yes' ? <Typography variant="h5" className={classes.boxheading}>{( notes.title || '' )}</Typography> : null }
            { showAverageScore && showAverageScore === 'yes' ? <Typography variant="h5" className={classes.boxheading}>Average Score: {average}</Typography> : null }
            { notes && notes.results && isArrayExists( notes.results ) ? notes.results.map(this.renderNote) : null } 
        </>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(SemanticSearchResults);