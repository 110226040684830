/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightCards' );

const Wrapper = styled.div`
    display: block;
`;

const useStyles = makeStyles((theme) => ({
    card: {
        background: '#fff',
        // height: '100%',
    },
    cardActionArea: {
        // height: '100%',
        // alignItems: 'flex-start',
    },
    cardActions: {
        justifyContent: 'space-between',
    }
}));

const NoteCard = ({
    id,
    folders,
    category_id,
    title,
    image = '/assets/images/default_note_image.jpeg',
    excerpt,
    status,
    onRead,
    onDelete,
}) => {
    const classes = useStyles();

    const handleLink = (event) => {
        event.preventDefault();

        onRead(id);
    }

    const handleDelete = (event) => {
        event.preventDefault();
        onDelete(id);
    }

    return (
    <Card className={classes.card}>
        <CardActionArea
            className={classes.cardActionArea}
            onClick={handleLink}
            >
            <CardMedia
                component="img"
                height="140"
                image={( image && !_isEmpty( image ) ? image : '/assets/images/default_note_image.jpeg' )}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {excerpt}
                </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions 
            disableSpacing
            className={classes.cardActions}>
            <Tooltip title="Read Note" aria-label="read" arrow placement="top">
                <IconButton onClick={handleLink}>
                    <SearchIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete Note" aria-label="delete" arrow placement="top">
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </CardActions>
    </Card>
    );
}

const InsightCards = ({
    notes,
    folders,
    onInsightRead,
    onInsightDelete
}) => {

    
    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <Grid container spacing={2}>
        { notes && isArrayExists( notes ) ? notes.map( ( note, index ) => {
            return (
            <Grid key={note.id} item xs={12} sm={6} md={4} lg={3}>
                <NoteCard
                    folders={folders}
                    {...note}
                    onRead={(id) => onInsightRead(id)}
                    onDelete={(id) => onInsightDelete(id)}
                    />
            </Grid>
            )
        }) : (
        <Grid item xs={12}>
            <Typography variant="subtitle1">No note(s) added yet to this folder.</Typography>
        </Grid>
        )}
        </Grid>
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightCards);