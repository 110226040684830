export const articleSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add']
    },
    { 
        id: 'user_uid', 
        label: 'User ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['add','update']
    },
    {   
        id: 'project_id',
        label: 'Project ID',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'action',
        label: 'Action',
        required: false,
        default: 'write_for_me',
        type: 'string',
        field: 'select',
    },
    {   
        id: 'title',
        label: 'Title',
        required: ['add'],
        default: '',
        type: 'string',
        field: 'text'
    },
    {   
        id: 'contents',
        label: 'Contents',
        required: false,
        default: '',
        type: 'string',
        field: 'tinymce_editor',
        skip: false
    },
    {   
        id: 'query',
        label: 'Query',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: false
    },
    {   
        id: 'notes',
        label: 'Notes',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: false
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update']
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date'
    },
    {   
        id: 'last_modified_by',
        label: 'Modified By',
        required: false,
        default: '',
        type: 'system_modified_by'
    }
];