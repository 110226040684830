/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_NOTES_LIST,
    GET_NOTES_OPTIONS,
    GET_NOTES,
	ADD_NOTES,
	EDIT_NOTES,
	DELETE_NOTES,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getNotesList, fb_getNotesOptions, fb_getNotes, fb_addNotes, fb_updateNotes, fb_deleteNotes } from './fb';


export const getNotesList = (props) => {
	return dispatch => {

		fb_getNotesList(props)
		.then(list => {
			dispatch({
				type: GET_NOTES_LIST,
				payload: { list }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_NOTES_LIST,
				payload: { list: [] }
			});
		})

	}
}

// export const getNotessOptions = (props) => {
// 	return dispatch => {

// 		fb_getNotesOptions(list => {
// 			dispatch({ 
//                 type: GET_NOTES_OPTIONS, 
//                 payload: { list } 
//             });
// 		},props);

// 	}
// }

export const getNotes = (id) => {
	return dispatch => {

		fb_getNotes(id)
		.then(notes => {
			dispatch({
				type: GET_NOTES,
				payload: { notes }
			});	
		})
		.catch(error => {
			triggerErrorAlert(getAPIErrorMessage(error));
			dispatch({
				type: GET_NOTES,
				payload: { notes: {} }
			});
		})

	}
}

export const addNotes = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addNotes(formData)
		.then( results => {
			if ( results && results.new_notes_id && !_isEmpty( results.new_notes_id ) ) {
				// updated
				dispatch({ type: ADD_NOTES });
				dispatch(toggleModalProcessing(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Notes Added");
				// redirect to notes page
				history.push("/notes/" + results.new_notes_id);
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new notes ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const editNotes = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateNotes(formData)
		.then( results => {
			fb_getNotes(formData.id)
			.then(notes => {
				// push new data
				dispatch({ type: GET_NOTES, payload: { notes } });	
				// updated
				dispatch({ type: EDIT_NOTES });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Notes Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const deleteNotes = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteNotes(id)
		.then( results => {
			fb_getNotesList(props)
			.then(list => {
				dispatch({ type: GET_NOTES_LIST, payload: { list } });	
				dispatch({ type: DELETE_NOTES });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Notes Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}