/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Form from './form';

import ModalView from '../../../components/ModalView';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import FormRadio from '../../../components/FormRadio';

import { FormBox } from '../../../styles/form';
import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix } from '../../../helpers/data';
import { doSchemaErrorCheck, isSkip } from '../../../helpers/schemas';

import { insightSchema } from '../../../schemas/insight';

const prefix = getClassPrefix( 'modules', 'InsightAddNew' );

const Wrapper = styled.div`
    padding: 0 0 20px 0;
`;

const SearchBoxWrapper = styled.div`
    .MuiTextField-root {
        background: #fff;
    }
`;

const getDefaultValue = () => {
    let val = {};
    insightSchema.forEach(schema => {
        if ( !isSkip(schema,'add') ) {
            switch (schema.id) {
                default:
                    val[schema.id] = ( schema.default || 0 === schema.default || '' === schema.default ? schema.default : null );
                    break;
            }
        } // end - schema
    });
    return val;
}

const InsightAddNew = ({
    open = false,
    folders,
    selectedFolder,
    onAddNew,
    onClose
}) => {
    const [ insight, setInsight ] = useState({});

    useEffect(() => {
        if ( open ) {
            let newInsight = getDefaultValue();
            if ( !_isEmpty( selectedFolder ) && selectedFolder !== 'all' ) {
                newInsight = update( newInsight, { 'category_id': { $set: selectedFolder } } );
            }
            setInsight( newInsight );
        } // end - open
    },[ open ]);

    const handleAddNew = () => {
        let error = false,
            formData = {};

        // do error check
        insightSchema.forEach(schema => {
            formData[schema.id] = ( insight && insight[schema.id] ? cloneCollections( insight[schema.id] ) : schema.default );

            switch( schema.id ) {
                case 'url':
                    if ( insight.type && insight.type === 'url' ) {
                        if ( !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) ) {
                            error = 'Please fill out "' + schema.label + '" field';
                        }
                    }
                    break;
                case 'title':
                    if ( insight.type && insight.type === 'manual' ) {
                        if ( !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) ) {
                            error = 'Please fill out "' + schema.label + '" field';
                        }
                    }
                    break;
            }

            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });
        
        if ( error ) {
            triggerErrorAlert( error );
        } else {
            onAddNew(formData);
        }
    }
    
    return <ModalView 
            open={open}
            title="Add New Note"
            onClose={onClose}
            // maxWidth={ insight.type && insight.type === 'url' ? 'sm' : 'md' }
            doAction={handleAddNew}
            contents={(
                <Form
                    insight={insight}
                    folders={folders}
                    onUpdate={(newValue) => setInsight(newValue)} />
            )} />
}

export default compose(
    connect(),
    withRouter
)(InsightAddNew);