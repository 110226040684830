/* eslint-disable */
import React from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import _isEmpty from 'lodash/isEmpty';
import _startsWith from 'lodash/startsWith';

import Header from '../Header';
import SideNav  from '../SideNav';
// import HeaderStudent from '../HeaderStudent';
import Footer from '../Footer';
import TopBar from '../TopBar';
import DotsLoader from '../DotsLoader';

import { FlexColumn } from '../../styles/division';
import { InfoButton, GreyButton } from '../../styles/button';
import theme from '../../theme';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { isAdminType, isUserType, isMentorRole } from '../../helpers/auth';
import { getClassPrefix } from '../../helpers/data';

import { logout } from '../../actions/auth';
import { toggleLoader } from '../../actions/global';
import { appChangesReset } from '../../actions/misc';

const prefix = getClassPrefix('components','AppWrapper');

const MainWrapper = styled.div`
    display: block
    background: #f5f9fc;
`;

const LayoutWrapper = styled.div`
    position: relative;
`;

const MainContentWrapper = styled.div`
    padding-left: 60px;
    padding-bottom: 60px;
    min-height: 100vh;
    background: #f5f9fc;
`;

const ContentOuterWrapper = styled.div`
    margin-top: 0px;
    padding: 0px 30px 30px 60px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '1170px')};
    padding: 15px 0px;
    margin: 0px;
`;

const TopBarHeight = styled.div`
    height: 70px;
    opacity: 0;
`;

class AppWrapper extends React.Component {

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleLogout = () => {
        const { changesNotSaved } = this.props;
        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                this.props.dispatch(logout());
            }
        } else {
            this.props.dispatch(logout());
        }
    }

    handleClick = (url,event) => {
        const { history, changesNotSaved, passingState } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url,( passingState && isObjectExists(passingState) ? passingState : null ));
            }
        } else {
            history.push(url,( passingState && isObjectExists(passingState) ? passingState : null ));
        }
    }

    isAdminView = () => {
        const { authData, history } = this.props;
        let adminView = false;
        // if is admin type, and not student pages
        if ( isAdminType( authData ) && history && history.location && history.location.pathname && !_startsWith( history.location.pathname, '/student/' ) ) {
            adminView = true;
        } // end - authData
        return adminView;
    }

    getHeaderProps = () => {
        const { headerprops } = this.props;
        return ( headerprops && !_isEmpty( headerprops ) ? headerprops : {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-end"
        } );
    }

    renderButtons() {
        const { buttons } = this.props;
        const btnStyle = {
            textAlign: 'center',
            minWidth: '200px',
            padding: "10px 5px",
            marginLeft: "5px"
        };
        return (
        <FlexColumn>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
            {buttons.map((button,index) => {
                if ( button.active ) {
                    return <InfoButton key={button.url} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
                } else {
                    return <GreyButton key={button.url} style={btnStyle} onClick={this.handleClick.bind(this,button.url)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
                }
            })}
            </Box>
        </FlexColumn>
        )
    }

    renderSubTitle() {
        const { subtitle } = this.props;
        return <Typography variant="h5" color="primary" style={{ fontWeight: "700", textTransform: "uppercase", marginBottom: "10px" }}>{subtitle}</Typography>;
    }

    renderTitle() {
        const { title } = this.props;
        return <Typography variant="h3" color="textPrimary" style={{ fontWeight: "700" }}>{title}</Typography>;
    }

    renderContent = () => {
        const { transition, onLoad, contentWrapperStyle } = this.props;
        if ( transition && transition == 'none' ) {
            return <div style={( contentWrapperStyle || { paddingTop: "30px" } )}>{ this.props.contents || '' }</div>;
        } else if ( transition && transition == 'zoom' ) {
            return <Zoom in={!onLoad} timeout={350}><div style={( contentWrapperStyle || { paddingTop: "30px" } )}>{ this.props.contents || '' }</div></Zoom>;
        } else if ( transition && transition == 'grow' ) {
            return <Grow in={!onLoad} timeout={350}><div style={( contentWrapperStyle || { paddingTop: "30px" } )}>{ this.props.contents || '' }</div></Grow>;
        } else {
            return <Fade in={!onLoad} timeout={350}><div style={( contentWrapperStyle || { paddingTop: "30px" } )}>{ this.props.contents || '' }</div></Fade>;
        }
    }

    renderLoader() {
        return <DotsLoader style={{ paddingTop: "60px", paddingBottom: "60px" }} />;
    }

    render() {
        const { authData, hideTopBar, topBarHeight, back, breadcrumbs, title, subtitle, buttons, onLoad, maxWidth, customColumn } = this.props;
        return (
        <MainWrapper>
            <LayoutWrapper
                className={prefix+'MainWrapper'}>
                <SideNav 
                    authData={authData}
                     />
                <MainContentWrapper 
                    className={prefix+'MainContentWrapper'}>
                    <Header />
                    <ContentOuterWrapper
                        className={prefix+'ContentOuterWrapper'}>
                        { hideTopBar || !( back && breadcrumbs && isArrayExists( breadcrumbs ) ) ? <TopBarHeight className={prefix + 'TopBarHeight'}>-</TopBarHeight> : <TopBar {...this.props} /> }
                        <ContentWrapper 
                            className={prefix+'ContentWrapper'}
                            maxWidth={( maxWidth || null )}>
                            <Box {...this.getHeaderProps()}>
                                <FlexColumn>
                                    { subtitle && !_isEmpty( subtitle ) ? this.renderSubTitle() : null }
                                    { title && !_isEmpty( title ) ? this.renderTitle() : null }
                                </FlexColumn>
                                { buttons && isArrayExists( buttons ) ? this.renderButtons() : null }
                                { customColumn && !_isEmpty( customColumn ) ? <FlexColumn>{customColumn}</FlexColumn> : null }
                            </Box>
                            { onLoad ? this.renderLoader() : this.renderContent()}
                        </ContentWrapper>
                    </ContentOuterWrapper>
                </MainContentWrapper>
            </LayoutWrapper>
            <Footer 
                authData={authData}
                adminView={this.isAdminView()}
                onLogout={this.handleLogout} />
        </MainWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth.user || null,
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(AppWrapper);