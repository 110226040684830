/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Divider from '@material-ui/core/Divider';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';
import { WrapWord } from '../../../styles/misc';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightFolders' );

const Wrapper = styled.div`
    background: #e5e5e5;
    height: 100%;
    padding: 20px 10px;
    min-height: 100vh;
    box-shadow:inset -2px 0 0 0 #ddd;
`;

const List = styled.ul`
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;

    & > li {
        display: block;
        margin: 0 0 5px 0;

        a {
            display: block;
            padding: 5px 10px;
            font-weight: 700;
            color: #676767;
            background: none;
            border-radius: 6px;
            
            &.selected,
            &:hover {
                color: #fff;
                background: #676767;
            }

            i {
                margin-right: 5px;
            }
        }

        &:last-child { margin-bottom: 0; }
    }
`;

const InsightFolders = ({
    folders,
    selectedFolder = 'all',
    onFolderChange
}) => {

    const handleLinkClick = (id) => (event) => {
        event.preventDefault();
        onFolderChange(id);
    }

    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <List>
            <li>
                <a 
                    href="#" 
                    className={selectedFolder === 'all' ? 'selected' : null}
                    onClick={handleLinkClick('all')}>
                    <i className="fa fa-sticky-note-o" aria-hidden="true"></i>
                    All Notes
                </a>
            </li>
            <li style={{ padding: '20px 0' }}>
                <Divider></Divider>
            </li>
            { folders && isArrayExists( folders ) ? _sortBy(folders, [f => f.label.toLowerCase()]).map( ( folder, index ) => { 
                return (
                <li key={folder.id}>
                    <a 
                        href="#" 
                        className={selectedFolder === folder.id ? 'selected' : null}
                        onClick={handleLinkClick(folder.id)}>
                        <i className="fa fa-folder-open-o" aria-hidden="true"></i>
                        {folder.label || ''}
                    </a>
                </li>
                );
            }) : null }
        </List>
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightFolders);