/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import firebase from 'firebase/app';
import moment from 'moment';
import TurndownService from 'turndown';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import amber from '@material-ui/core/colors/amber';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';
import _reverse from 'lodash/reverse';
import _random from 'lodash/random';

import SemanticSearchResults from '../NotesPage/results';

import ModalView from '../../components/ModalView';
import DotsLoader from '../../components/DotsLoader';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { InverseButton, GreyButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';

let turndownService = new TurndownService();
turndownService.addRule('breakline', {
    filter: ['p', 'div', 'ul','ol','blockquote'],
    replacement: function (content) {
        return content + '__|S|__';
    }
});
turndownService.addRule('listitem', {
    filter: ['li'],
    replacement: function (content) {
        return '* '+content + '__|S|__';
    }
});
turndownService.addRule('removeimglink', {
    filter: ['img', 'a'],
    replacement: function (content) {
        return '';
    }
});

const MainWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: ${props => (props.maxWidth ? props.maxWidth : "600px")};
    p {
        font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
        line-height: 1.75em;
        margin-bottom: 0.85em;
    }
`;

const NotesWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
`;

class ReaderMode extends React.Component {

    utcOffSet = 8;
    
    state = {
        modalData: false,
        items: [],
        randNum: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.handleDataLoad();
        } // end - open
    }

    handleDataLoad = () => {
        const { selected } = this.props;
        let promises = [];
        _forEach( selected, item => {
            promises.push( firebase.firestore().collection("z_akimbo_fr6_posts").doc( item.id ).get() );
        });
        Promise.all( promises )
        .then( results => {
            let items = [];
            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result.exists ) {
                        items.push( result.data() );
                    }
                });
            } // end - results
            this.setState({ modalData: this.getInitialModalData(), items, randNum: _random(1,9999) });
        })
        .catch( error => {
            triggerErrorAlert( getAPIErrorMessage(error) );
        });
        
    }

    handleClose = () => {
        const { onClose } = this.props;
        this.setState({ modalData: this.getInitialModalData(), items: [], randNum: false });
        if ( onClose )
            onClose();
    }

    handleFormUpdate = ( newValue, key ) => {
        const { modalData } = this.state;
        let newData = ( modalData && !_isEmpty( modalData ) ? cloneCollections( modalData ) : {} );

        switch( key ) {
            // case 'contents':
            //     newData[key] = turndownService.turndown(( newValue || '' ));
            //     break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        this.setState({ modalData: newData });
    }

    getInitialModalData = () => {
        return {
            sortBy: 'title-asc',
            display_option: 'all',
            show_title: 'yes',
            show_score: 'no',
            maxWidth: 600,
            fontSize: 20
        };
    }

    convertData = (item) => {
        let posts = [],
            data = {
                id: item.id,
                title: item.title,
                user_id: item.user_id,
                results: []
            };

        if ( item && item.zcontents && isObjectExists( item.zcontents ) ) {
            _forEach( item.zcontents, post => {
                posts.push(post);
            });
        } // end - item.zcontents

        if ( posts && isArrayExists( posts ) ) {
            posts = _sortBy( posts, ['id'] );
            _forEach( posts, post => {
                let content = turndownService.turndown( '<p>'+( post.name || '' )+'</p>'+post.content ),
                    count = 0,
                    splitData = content.split('__|S|__');

                _forEach( splitData, string => {
                    count++;
                    data.results.push({
                        id: post.id+'-'+count,
                        string,
                        score: 0
                    });
                });

                count++;
                data.results.push({
                    id: post.id+'-'+count,
                    string: '---',
                    score: 0
                });
            });
        }

        return data;
    }

    getSelectedNotes = () => {
        const { selected } = this.props;
        const { modalData, items } = this.state;
        let list = [];

        if ( selected && isArrayExists( selected ) ) {
            _forEach( selected, item => {
                let selectedItem = _find( items, { id: item.id } );
                if ( selectedItem )
                    list.push( this.convertData( selectedItem ) );
            });
        } // end - selected

        // sort by
        if ( modalData && modalData.sortBy && !_isEmpty( modalData.sortBy ) ) {
            switch( modalData.sortBy ) {
                case 'title-desc':
                    list = _sortBy( list, ['title'] );
                    list = _reverse( list );
                    break;
                case 'title-asc':
                    list = _sortBy( list, ['title'] );
                    break;
            }
        } // end - modalData.sortBy

        return list;
    }

    renderItem = (notes) => {
        const { modalData } = this.state;
        return (
        <NotesWrapper key={notes.id}>
            <SemanticSearchResults 
                showAverageScore="no"
                showTitle={( modalData && modalData.show_title || false )}
                notes={( notes || false )}
                display_option={( modalData && modalData.display_option || false)}
                show_color="no"
                show_score={( modalData && modalData.show_score || false )} />
        </NotesWrapper>
        )
    }

    renderOptions = () => {
        const { modalData } = this.state;
        return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <FormSelect 
                    label="Sort By" 
                    name="sortBy"
                    value={( modalData && modalData.sortBy ? modalData.sortBy : '' )} 
                    options={[
                        { value: 'title-asc', label: 'Title ( A - Z)' },
                        { value: 'title-desc', label: 'Title ( Z - A )' },
                    ]} 
                    disableNative={true} 
                    onChange={this.handleFormUpdate} />
            </Grid>
            <Grid item xs={2}>
                <FormSelect 
                    label="Display Options" 
                    name="display_option"
                    value={( modalData && modalData.display_option ? modalData.display_option : '' )} 
                    options={[
                        { value: 'all', label: 'All' },
                        { value: 'above-average', label: 'Above Average Only' },
                        { value: 'top-25', label: 'Top 25%' },
                        { value: 'top-10', label: 'Top 10%' },
                    ]} 
                    disableNative={true} 
                    onChange={this.handleFormUpdate} />
            </Grid>
            <Grid item xs={2}>
                <FormSelect 
                    label="Show Title" 
                    name="show_title"
                    value={( modalData && modalData.show_title ? modalData.show_title : '' )} 
                    options={[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                    ]} 
                    disableNative={true} 
                    onChange={this.handleFormUpdate} />
            </Grid>
            <Grid item xs={2}>
                <FormSelect 
                    label="Show Score" 
                    name="show_score"
                    value={( modalData && modalData.show_score ? modalData.show_score : '' )} 
                    options={[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                    ]} 
                    disableNative={true} 
                    onChange={this.handleFormUpdate} />
            </Grid>
            <Grid item xs={2}>
                <Typography gutterBottom>Width</Typography>
                <Slider
                    value={( modalData && modalData.maxWidth ? modalData.maxWidth : 600 )}
                    step={50}
                    min={200}
                    max={1000}
                    onChange={(event, newValue) => {
                        this.handleFormUpdate(newValue,'maxWidth');
                    }} />
            </Grid>
            <Grid item xs={2}>
                <Typography gutterBottom>Font Size</Typography>
                <Slider
                    value={( modalData && modalData.fontSize ? modalData.fontSize : 20 )}
                    step={1}
                    min={10}
                    max={30}
                    onChange={(event, newValue) => {
                        this.handleFormUpdate(newValue,'fontSize');
                    }} />
            </Grid>
        </Grid>
        )
    }

    renderModalForm = () => {
        const { modalData } = this.state;
        const list = this.getSelectedNotes();
        return (
        <>
            {this.renderOptions()}
            { list && isArrayExists( list ) ? <MainWrapper
                maxWidth={( modalData && modalData.maxWidth ? modalData.maxWidth + 'px' : null )}
                fontSize={( modalData && modalData.fontSize ? modalData.fontSize + 'px' : null )}
                >{list.map(this.renderItem)}</MainWrapper> : null }
        </>
        );
    }

    render() {
        const { open } = this.props;
        const { randNum } = this.state;
        return (
        <>
            <ModalView 
                open={open}
                title={"Reader Mode"}
                onClose={this.handleClose}
                disableBackdrop={true}
                maxWidth="lg"
                noAction={true}
                cancelLabel="Close"
                contents={ randNum ? this.renderModalForm() : <DotsLoader /> } />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ReaderMode);