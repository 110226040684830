/* eslint-disable */
import React, { useMemo, useState } from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import _startsWith from 'lodash/startsWith';

// import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { getClassPrefix, getSelectedValue } from '../../helpers/data';

import theme from '../../theme';

import { PLATFORM_ORG_LOGO } from '../../constants';

const prefix = getClassPrefix('components','SideNav');

const Wrapper = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: #3e3e3e;
    width: 60px;
    height: 100%;
    box-shadow: 10px 0 10px -10px rgba(0,0,0,0.15);
`;

const InnerWrapper = styled.div`
    padding: 10px 0px;
`;

const LogoWrapper = styled.div`
    padding: 10px 5px 20px 5px;
    height: 80px;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
`;

const NavWrapper = styled.nav`
    display: block;

    ul {
        display: block;
        align-items: center;
        list-style: none;

        li {
            display: block;
            padding: 0px;
            margin: 0px;
        }

    }

    a {
        display: block;
        padding: 6px 10px;
        font-size: 24px;
        text-align: center;
        color: #ddd;
        font-weight: 700;
        box-shadow:inset 0 0 0 0 #ddd;
        text-transform: uppercase;

        
        &:hover,
        &:active,
        &.active {
            color: #fff;
            background: rgba(255,255,255,0.15);
        }
    }
`;

const ToolTipLabel = styled.span`
    display: inline-block;
    font-size: 14px;
    padding: 3px;
`;

const Nav = ({
    authData,
    location,
    history
}) => {

    const links = useMemo(() => {
        let links = [
            { id: 'dashboard', label: 'Dashboard', icon: 'fa fa-th-large', url: '/dashboard' }
        ];

        links.push({ id: 'train', label: 'Train AI', icon: 'fa fa-bolt', url: '/train' });
        links.push({ id: 'articles', label: 'Articles', icon: 'fa fa-edit', url: '/articles' });
        links.push({ id: 'insights', label: 'Insights', icon: 'fa fa-lightbulb-o', url: '/insights' });
        links.push({ id: 'notes', label: 'Notes', icon: 'fa fa-file-text-o', url: '/notes' });
        links.push({ id: 'fr6', label: 'FR6', icon: 'fa fa-podcast', url: '/fr6' });

        return links;
    },[ authData ]);

    const handleLinkClick = (url) => event => {
        event.preventDefault();
        history.push(url);
    }

    const isCurrentPage = (url) => {
        return location && location.pathname && !_isEmpty( location.pathname ) && _startsWith( url, location.pathname ) ? true : false ;
    }

    return (
    <NavWrapper
        className={prefix+"NavWrapper"}>
        <ul>
            {links.map( link => {
                if ( link.submenu && isArrayExists( link.submenu ) )
                    return (
                    <li key={link.id}>
                        {/* <a href="#" 
                            className={( subMenuID && subMenuID === link.id ? 'active' : null )} 
                            onClick={this.handleSubMenu.bind(this,link.id)}
                            >
                                <span>{link.label}</span>
                            </a> */}
                    </li>
                    );
                else
                    return (
                    <li key={link.id}>
                        <Tooltip title={<ToolTipLabel>{( link.label || '' )}</ToolTipLabel>} placement="right" arrow>
                            <a 
                                href={link.url} 
                                className={( isCurrentPage( link.url ) ? 'active' : null )} 
                                onClick={handleLinkClick(link.url)}>
                                <i className={link.icon}></i>
                            </a>
                        </Tooltip>
                    </li>
                    );
            })}
        </ul>
    </NavWrapper>
    );
}

const SideNav = (props) => {
    const {
        authData,
        location,
        history
    } = props;
    
    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <InnerWrapper
            className={prefix+"InnerWrapper"}>
            <LogoWrapper className={prefix+"LogoWrapper"}><img src="/assets/images/sa-avatar-512_minified.png" /></LogoWrapper>
            <Nav {...props} />
        </InnerWrapper>
    </Wrapper>
    )
}

export default compose(
    withRouter
)(SideNav);