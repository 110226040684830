import _random from 'lodash/random';

import { 
	GET_INSIGHTS,
    GET_INSIGHTS_CATEGORIES,
    GET_INSIGHTS_OPTIONS,
    GET_INSIGHT,
	ADD_INSIGHT,
	EDIT_INSIGHT,
	DELETE_INSIGHT,
    RESET_INSIGHT,
    RESET_INSIGHTS
} from '../actions/types';

const initialState = {
    insights: null,
    insight: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const insights = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_INSIGHTS:
            return {
                ...state,
                insights: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_INSIGHTS_CATEGORIES:
            return {
                ...state,
                categories: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_INSIGHTS_OPTIONS:
            return {
                ...state,
                insights_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case GET_INSIGHT:
            return {
                ...state,
                insight: ( payload.insight || {} ),
                rand: _random(1,9999)
            }; 
        case ADD_INSIGHT:
        case EDIT_INSIGHT:
        case DELETE_INSIGHT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_INSIGHT:
            return {
                ...state,
                insight: null,
                updated: false,
                rand: false
            };
        case RESET_INSIGHTS:
            return {
                ...state,
                insights: null,
                rand: false
            };
        default:
            return state;
    }
}