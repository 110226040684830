/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import update from 'immutability-helper';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

import TopBar from './parts/editor_topbar';
import Panel from './parts/editor_panel';
import Editor from './parts/editor';

import AppWrapper from '../../components/AppWrapper';
import SnackBarSave from '../../components/SnackBarSave';
// import ModelDelete from '../../components/ModalDelete';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, getClassPrefix, doArraySearch } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert} from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';
import { doSchemaErrorCheck } from '../../helpers/schemas';


import { fb_getInsight, fb_getInsightsCategories, fb_updateInsight, fb_triggerInsight } from './api/api';
import { toggleLoader } from '../../actions/global';
import { appChangesMade, appChangesReset } from '../../actions/misc';

import { insightSchema } from '../../schemas/insight';

const prefix = getClassPrefix( 'modules', 'Insight' );

const Wrapper = styled.div`
    .${getClassPrefix('components','AppWrapper')}MainContentWrapper {
        padding-bottom: 0px;
    }
    .${getClassPrefix('components','AppWrapper')}ContentOuterWrapper {
        padding: 0px;
    }
    .${getClassPrefix('components','AppWrapper')}ContentWrapper {
        padding: 0px;
    }
    .${getClassPrefix('components','AppWrapper')}TopBarHeight {
        height: 0px;
    }
`;

const PageContentWrapper = styled.div`
    background: #ffffff;
    padding: 0px 0px 0px 0px;
    min-height: 100vh;
    border-top: 2px solid #ddd;
`;

const FormWrapper = styled.div`
    background: #fff;
    padding: 30px 50px;
    max-width: 900px;
    margin: 0 auto;
`;

const PanelWrapper = styled.div`
    background: #f9f9f9;
    min-height: 100vh;
    height: 100%;
    box-shadow:inset 2px 0 0 0 #ddd;
`;

const Insight = ({
    authData,
    changesMade,
    match,
    history,
    dispatch
}) => {
    const { insight_id } = match.params;
    const [ insight, setInsight ] = useState( {} );
    const [ currentInsight, setCurrentInsight ] = useState( {} );
    const [ insightCategories, setInsightCategories ] = useState( [] );

    useEffect(() => {
        let mounted = true,
            promises = [];

        dispatch( toggleLoader( true ) );
        promises.push( fb_getInsight(insight_id) );
        promises.push( fb_getInsightsCategories() );

        Promise.all( promises )
        .then( ( [insight, insightCategories] ) => {
            if ( mounted ) {
                dispatch( toggleLoader( false ) );
                let insightData = cloneCollections( insight );
                // if contents_html not found
                if ( !( insightData.contents_html && !_isEmpty( insightData.contents_html ) ) && insightData.contents && isArrayExists( insightData.contents ) ) { 
                    insightData.contents_html = '';
                     insightData.contents.forEach( ( content, index ) => {
                        let paragraphs = ( content.contents && !_isEmpty( content.contents ) ? content.contents.split('. ') : '' );
                        insightData.contents_html += ( content.heading && !_isEmpty( content.heading ) ? '<h2>' + content.heading + '</h2>' : '' );

                        if ( paragraphs && isArrayExists( paragraphs ) ) {
                            paragraphs.forEach( ( paragraph) => {
                                insightData.contents_html += '<p>' + paragraph + '.</p>';
                            });
                        } // end - paragraphs
                    });
                } // end - insightData
                setInsight( insightData );
                setCurrentInsight( insightData );
                setInsightCategories( insightCategories );
            }
        })
        .catch( error => {
            dispatch( toggleLoader( false ) );
            triggerErrorAlert( getAPIErrorMessage(error) );
        });

        return () => {
            // reset
            mounted = false;
            // dispatch(resetRedux('insight'));
        }
    },[ insight_id ]);

    const doInsightAction = (action = 'update') => {
        let promises = [],
            formData = {},
            error = false;

        // do error check
        insightSchema.forEach(schema => {
            switch( schema.id ) {
                case 'contents':
                case 'insights':
                    // skip
                    break;
                default:
                    formData[schema.id] = ( insight && insight[schema.id] ? cloneCollections( insight[schema.id] ) : schema.default );
                    if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                        error = 'Please fill out "' + schema.label + '" field';
                    }
                    break;
            }
        });

        if ( error ) {
            triggerErrorAlert( error );
        } else {
            dispatch( toggleLoader(true) );

            if ( action === 'trigger' ) {
                promises.push( fb_triggerInsight( formData ) );
            } else {
                promises.push( fb_updateInsight( formData ) );
            }
            
            Promise.all( promises )
            .then(() => {
                // retrieve new list
                return fb_getInsight(formData.id);
            })
            .then(newInsight => {
                dispatch( toggleLoader(false) );
                setInsight( newInsight );
                setCurrentInsight( newInsight );
                dispatch(appChangesReset());
                triggerSuccessAlert("Insight Updated");
            })
            .catch( (error) => {
                dispatch( toggleLoader(false) );
                triggerErrorAlert( getAPIErrorMessage(error) );
            });
        }
    }

    const handleSaveChanges = () => {
        doInsightAction('update');
    }

    const handleInsightTrigger = () => {
        doInsightAction('trigger');
    }

    const handleUpdate = (newValue,key) => {
        const newInsight = update(insight, { [key]: { $set: newValue } });
        setInsight( newInsight );
        if ( !_isEqual( newInsight, currentInsight ) ) 
            dispatch(appChangesMade());
    }

    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <AppWrapper 
            title=""
            maxWidth="100%"
            contentWrapperStyle={{ paddingTop: '0px' }}
            onLoad={false}
            contents={ authData ? (
            <>
                <TopBar
                    insight={insight}
                    currentInsight={currentInsight}
                    folders={insightCategories}
                    onUpdate={handleUpdate}
                    onGoBack={() => {
                        if ( changesMade ) {
                            let answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
                            // reset status if answer is yes
                            if ( answer ) {
                                dispatch(appChangesReset());
                                history.goBack();
                            }
                        } else {
                            history.goBack();
                        }
                    }} />
                <PageContentWrapper>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <FormWrapper>
                                <Editor
                                    content={( currentInsight.contents_html && !_isEmpty( currentInsight.contents_html ) ? currentInsight.contents_html : '' )}
                                    onUpdate={(newValue,editor) => handleUpdate(newValue,'contents_html')} />
                            </FormWrapper>
                        </Grid>
                        <Grid item xs={3}>
                            <PanelWrapper>
                                <Panel
                                    insight={insight}
                                    onInsightTrigger={handleInsightTrigger}
                                    onUpdate={handleUpdate}
                                    />
                            </PanelWrapper>
                        </Grid>
                    </Grid>
                </PageContentWrapper>

                <SnackBarSave onSave={handleSaveChanges} />

            </>
            ) : null } />
    </Wrapper>
    );
}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
        // insightsList: state.insights && state.insights.insights ? state.insights.insights : null,
        // insightsCategoryList: state.insights && state.insights.categories ? state.insights.categories : null,
        // randNum: state.insights && state.insights.rand ? state.insights.rand : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Insight);