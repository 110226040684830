export const insightSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add'],
        sync: true
    },
    { 
        id: 'status', 
        label: 'Status',
        required: false, 
        default: 'new', 
        type: 'system_data', 
        sync: true
    },
    { 
        id: 'user_uid', 
        label: 'User ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['add','update'],
        sync: true
    },
    {   
        id: 'category_id',
        label: 'Folder',
        required: ['add'],
        default: '',
        type: 'string',
        field: 'select',
        sync: true
    },
    {   
        id: 'type',
        label: 'Type',
        required: false,
        default: 'url',
        type: 'string',
        field: 'radio',
        sync: true
    },
    {   
        id: 'url',
        label: 'URL',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        sync: true
    },
    {   
        id: 'website',
        label: 'Website URL',
        required: false,
        default: '',
        type: 'string',
        sync: true
    },
    {   
        id: 'image',
        label: 'Image',
        required: false,
        default: '',
        type: 'string',
        sync: true
    },
    {   
        id: 'title',
        label: 'Title',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        sync: true
    },
    {   
        id: 'desc',
        label: 'Description',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        sync: true
    },
    {   
        id: 'contents_html',
        label: 'HTML Contents',
        required: false,
        default: [],
        type: 'string',
        field: 'textarea',
        skip: false
    },
    {   
        id: 'contents',
        label: 'Contents',
        required: false,
        default: [],
        type: 'array_obj',
        skip: false
    },
    {   
        id: 'insights',
        label: 'Insights',
        required: false,
        default: {},
        type: 'obj',
        disabled: ['update'],
        skip: false
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update'],
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        sync: true
    }
];