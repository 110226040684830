/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import amber from '@material-ui/core/colors/amber';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _toString from 'lodash/toString';

import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getAPIErrorMessage } from '../../helpers/action';

import { InverseButton, GreyButton } from '../../styles/button';
import { WarningBox } from '../../styles/message';

const Wrapper = styled.div`
    background: #fff;
    padding: 20px;
    border: 2px solid #212121;
`;

class SemanticSearchResults extends React.Component {

    utcOffSet = 8;
    
    state = {
        modalData: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {  open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ modalData: this.getInitialModalData() });
        } // end - open
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    getInitialModalData = () => {
        return {};
    }

    renderItem = (item) => {
        const { data } = this.props;
        return (
        <Grid key={item.id} container spacing={2}>
            <Grid item xs={10}>
                <Typography variant="body1" style={{ background: ( item.score > data.average ? amber['300'] : 'none' ) }}>{( item.string || '' )}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body1" align="right">{( item.score || '' )}</Typography>
            </Grid>
        </Grid>
        )
    }

    renderModalForm = () => {
        const { data } = this.props;
        const { modalData } = this.state;
        return (
        <>
           { data && data.list && isArrayExists( data.list ) ? data.list.map(this.renderItem) : null } 
           
        </>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <>
            <ModalView 
                open={open}
                title={"Semantic Search Results"}
                onClose={this.handleClose}
                disableBackdrop={true}
                maxWidth="lg"
                noAction={true}
                cancelLabel="Close"
                contents={this.renderModalForm()} />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(SemanticSearchResults);