import _random from 'lodash/random';

import { 
	GET_ARTICLES,
    GET_ARTICLES_OPTIONS,
    GET_ARTICLE,
	ADD_ARTICLE,
	EDIT_ARTICLE,
	DELETE_ARTICLE,
    RESET_ARTICLE,
    RESET_ARTICLES
} from '../actions/types';

const initialState = {
    articles: null,
    article: null,
    updated: false,
    rand_options: false,
    rand: false
};

export const articles = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ARTICLES:
            return {
                ...state,
                articles: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_ARTICLES_OPTIONS:
            return {
                ...state,
                articles_options: ( payload.list || [] ),
                rand_options: _random(1,9999)
            };
        case GET_ARTICLE:
            return {
                ...state,
                article: ( payload.article || {} ),
                rand: _random(1,9999)
            }; 
        case ADD_ARTICLE:
        case EDIT_ARTICLE:
        case DELETE_ARTICLE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_ARTICLE:
            return {
                ...state,
                article: null,
                updated: false,
                rand: false
            };
        case RESET_ARTICLES:
            return {
                ...state,
                articles: null,
                rand: false
            };
        default:
            return state;
    }
}