/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import ReaderMode from './reader';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';


class List extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openReaderModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { course_type } = this.props;
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            // case 'course_type':
            //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
            //     newData[key] = newValue;
            //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
            //     break;
            default:
                if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.title && !_isEmpty( modalData.title ) ) ) {
            error = 'Please enter a valid notes title';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            
        } // end - error
    }

    handleView = (item) => (event) => {
        if ( event && event.preventDefault )
            event.preventDefault();

        this.setState({ openReaderModal: true, checked: [{ id: item.id }] });

        // this.props.history.push("/fr6/"+item.id);
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {

        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [];

        // actions.push({ 
        //     id: 'addnew', label: 'Add New Notes', icon: 'fa-plus-circle', onClick: () => this.setState({
        //         openViewModal: true, 
        //         modalData: {
        //             title: '',
        //         } 
        //     }) 
        // });

        actions.push({ 
            id: 'reader_mode', label: 'Enable Reader Mode', icon: 'fa-search', onClick: () => {
                const { checked } = this.state;
                if ( checked && isArrayExists( checked ) ) {
                    this.setState({ openReaderModal: true });
                } else {
                    triggerErrorAlert("Please select at least one item");
                } // end - checked
            }
        });

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { list } = this.props;
        let items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['title'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            items = _filter( items, { category_id: filterBy });
            total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['modified_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['modified_on'] );
                    break;
                case 'title-desc':
                    items = _sortBy( items, ['title'] );
                    items = _reverse( items );
                    break;
                case 'title-asc':
                    items = _sortBy( items, ['title'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'publish':
                return item.publish && item.publish == 'yes' ? <SuccessTag>Yes</SuccessTag> : <GreyTag>No</GreyTag>;
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderAddNewForm = () => {
        const { course_type } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <FormInput label="Notes Title *" name="title" value={( modalData.title || '' )} onChange={this.handleFormUpdate} />
        </div>
        );
    }

    renderTableActions = () => {
        const { authData, categories } = this.props;
        const { filterBy, sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'title-asc', label: 'Title ( A - Z)' },
                    { value: 'title-desc', label: 'Title ( Z - A )' },
                ]}

                // leftButtons={ checked && isArrayExists( checked ) ? [
                //     <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                //         <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                //     </div>
                //     ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    // <InfoButton minWidth="168px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({
                    //         openViewModal: true, 
                    //         modalData: { 
                    //             title: '',
                    //         } 
                    //     })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                    // ,
                    // <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                filterByOptions={getSelectOptions({ list: ( categories || false ), options: [{ value: 'all', label: 'All Categories' }], keys: { value: 'id', label: 'title' }, sortBy: 'label' })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={true}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, categories } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openReaderModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            { authData && hasAccessRights(authData,['atw']) ? <ModalView 
                open={openViewModal}
                title="Add New Notes"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} /> : null }

            <ReaderMode
                open={openReaderModal}
                selected={checked}
                onClose={() => this.setState({ openReaderModal: false, checked: false, allCheck: false })} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'category_id', label: 'Category', render: (item) => getSelectedValue( categories, item.category_id, 'id', 'title', '-' ) },
                    { id: 'title', label: 'Title', render: (item) => ( item.title || '' ) },
                    { id: 'modified_on', label: 'Last Updated On', render: (item) => ( item.modified_on ? getMomentTime( item.modified_on, 'YYYY-MM-DD HH:mm:ss' ) : '-' ) },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="view" size="small" onClick={this.handleView(item)}><i className="fa fa-search"></i>View</InfoButton>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(List);