import { 
    // api_env,
    } from './env';

export const FIREBASE_ACCOUNT_ID = 'saai-e5a56';
export const FIREBASE_REGION = 'us-central1';
export const FIREBASE_FUNCTIONS_REGION = 'us-central1';

// emulator port - make sure it's same as firebase.json
export const USE_EMULATOR = false;
export const EMULATOR_PORT_CLOUD_FUNCTIONS = 5001;
export const EMULATOR_PORT_FIRESTORE = 8080;
export const EMULATOR_PORT_DATABASE = 9000;
export const EMULATOR_PORT_UI = 6900;

export const FIREBASE_CONFIG_DATA = {
    apiKey: "AIzaSyBQWGux7G6pCD9IjBfShx-i7bgJi-LcF-8",
    authDomain: "saai-e5a56.firebaseapp.com",
    databaseURL: "https://saai-e5a56-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "saai-e5a56",
    storageBucket: "saai-e5a56.appspot.com",
    messagingSenderId: "533592218565",
    appId: "1:533592218565:web:8a96c37567ecfdd690c9ac"
};