/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import EditForm from './form';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getArticle, editArticle } from '../../actions/articles';
// import { getMaintenance } from '../../actions/maintenance';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { articleSchema } from '../../schemas/article';

class ArticlePage extends React.Component {

    state = {
        article: false,
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;
        const { article_id } = this.props.match.params;
        
        // retrieve article details
        this.props.dispatch(getArticle(article_id));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentArticle, randNum, 
            } = this.props;

        // for article
        if ( currentArticle && randNum && randNum !== this.state.randNum ) {
            this.setState({ article: this.getArticleData( currentArticle ), randNum });
        } // end - currentArticle
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('article'));
    }

    handleRefresh = (type) => {
        // const { article } = this.props.match.params;
        // switch( type ) {
        //     case 'emails':
        //         this.setState({ emails: false });
        //         this.props.dispatch(getEmailsBy('courses',article));
        //         break;
        // }
    }

    handleSaveChanges = (event) => {
        const { article } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        articleSchema.forEach(schema => {
            formData[schema.id] = ( article && article[schema.id] ? cloneCollections( article[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editArticle(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ article: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getArticleData = (currentArticle) => {
        let article = cloneCollections( currentArticle );
        // if ( article.action && (
        //     article.action === 'sentence_to_story_seth' || 
        //     article.action === 'sentence_to_story_ben'
        // ) )
        //     article.max_token = 650;

        return article
    }

    renderForm = () => {
        const { authData, currentArticle, changesMade } = this.props;
        const { article } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            article: ( article || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        return <EditForm 
                formType="update"
                currentArticle={( currentArticle || false )}
                {...tabProps} />
    }

    renderContents() {
        return (
        <div>

            {this.renderForm()}
            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentArticle } = this.props;
        return <AppWrapper 
                // title="Edit Article"
                // subtitle="Articles"
                back="/articles"
                breadcrumbs={[
                    { url: '/articles', label: 'Articles' },
                    { label: 'Edit' }
                ]}
                maxWidth="100%"
                contentWrapperStyle={{ padding: '0px' }}
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentArticle && currentArticle.id && !_isEmpty( currentArticle.id ) ? this.renderContents() : <ItemNotFound item="Article" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentArticle: state.articles && state.articles.article ? state.articles.article : null,
        randNum: state.articles && state.articles.rand ? state.articles.rand : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ArticlePage);