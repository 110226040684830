/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';
import _random from 'lodash/random';

import { callFunctionsAPI } from '../helpers/action';
import { isArrayExists } from '../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const getPromptData = () => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("seths_blog").orderBy('id_num','asc').startAt(_random(16951,40000)).limit(1).get()
        .then(snapshot => {
            let item = false;
            if ( snapshot ) {
                snapshot.forEach(doc => {
                    item = doc.data();
                });
            } // end - snapshot
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });
        

    })
}

export const triggerTrainAction = (formData,action) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'train', action, formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}