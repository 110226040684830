/* eslint-disable */
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

// import ModelDelete from '../../../components/ModalDelete';
// import ModalView from '../../../components/ModalView';
import FormInput from '../../../components/FormInput';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../../styles/button';
import { SuccessTag, GreyTag } from '../../../styles/tag';

import { isArrayExists } from '../../../helpers/validation';
import { triggerErrorAlert } from '../../../helpers/alert';
import { cloneCollections, getClassPrefix } from '../../../helpers/data';

const prefix = getClassPrefix( 'modules', 'InsightCardsTopBar' );

const Wrapper = styled.div`
    padding: 0 0 20px 0;
`;

const SearchBoxWrapper = styled.div`
    .MuiTextField-root {
        background: #fff;
    }
`;

const InsightCardsTopBar = ({
    searchterm = '',
    onSearchtermChange,
    onAddNew
}) => {

    const handleAddNew = (event) => {
        event.preventDefault();
        onAddNew();
    }
    
    return (
    <Wrapper
        className={prefix+"Wrapper"}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
                <SearchBoxWrapper>
                    <FormInput
                        label="Search"
                        value={searchterm}
                        onChange={(newValue) => onSearchtermChange(newValue)}
                        />
                </SearchBoxWrapper>
            </Grid>
            <Grid item xs={12} sm={9} align="right">
                <InfoButton onClick={handleAddNew} style={{ padding: '10px 25px', borderRadius: '25px' }}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
            </Grid>
        </Grid>
    </Wrapper>
    )
}

export default compose(
    connect(),
    withRouter
)(InsightCardsTopBar);